<template>
  <div class="nav-header">
    <v-navigation-drawer v-model="drawer" app absolute temporary>
      <v-list-item class="px-2 img-sidebar-logo">
        <v-img src="@/assets/img/full_logo.png" style="max-width: 70px !important; margin-top: 50px;"></v-img>
      </v-list-item>
      <v-subheader style="max-height: 20px !important;"></v-subheader>
      <v-list dense style="margin-top: 20px;">
        <template v-for="item in menu_items">
          <v-list-item 
            :key="item.title" 
            v-if="(item.available && item.items == undefined)" 
            :target="item.target ? item.target : '_self'"
            :href="item.href" 
            :disabled="item.disabled" 
            :class="item.active"
            class="primary--text" link>
            <v-list-item-icon>
              <div class="icon-list-sidebar">
                <v-img v-if="item.img!=''" :src="item.img"/>
                <v-icon v-else style="color: #C91C22;">{{ item.icon }}</v-icon>
              </div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 14px; line-height: 2rem;">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-if="item.available && item.items != undefined" :prepend-icon="item.icon" :key="item.title" no-action :disabled="item.disabled">
            <template v-slot:activator>
              <v-list-item-content dense>
                <v-list-item-title style="font-size: 14px">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="(child, idx) in item.items">
              <v-list-item v-if="child.available" :key="idx" :href="child.href" link :disabled="child.disabled">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="white" dark :dense="true" style="max-height: 40px !important">
      <span class="mr-2" @click="onClick" style="cursor: pointer" title="Go to Home">
        <v-img src="@/assets/img/full_logo.png" style="max-width: 35px !important"></v-img>
      </span>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-show="isLoggedIn" class="icon-navbar">
        <v-icon class="toggle-nav">mdi-menu-open</v-icon>
      </v-app-bar-nav-icon>

      <div class="d-flex align-center titleDrawer" xs12>
          <core-title :title="$route.name" 
          color="#C91C22" :fontSize="18" :subtitle="`Last Update ${lastUpdate}`" :subtitleStyle="`margin-left: 2px;font-style:italic;font-size: 10px`" />
      </div>
      <v-spacer></v-spacer>
      <span class="mr-2" :title="isOnline ? 'Online':'Offline'" :style="isOnline?`color:green!important;caret-color:green!important;`:`color:#C91C22!important;caret-color:#C91C22!important;`">
        <v-avatar size="30">
          <v-icon :color="isOnline?'green':'red'">{{ isOnline ? 'mdi-access-point-network':'mdi-access-point-network-off' }}</v-icon>
        </v-avatar>
      </span>
      <div class="user-profile-wrapper">
        <v-menu bottom v-if="isLoggedIn">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <span class="indicator"></span>
              <span class="user-name">
                  {{ $store.getters["auth/getSession"]
                    ? $store.getters["auth/getSession"].sName
                    : "Not Login" }}
              </span>
              <v-icon dark>mdi-chevron-down</v-icon>
              <span class="mobile-icon"><v-icon dark>mdi-account</v-icon></span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $store.getters["auth/getSession"] ? $store.getters["auth/getSession"].sName : "#N/A" }}</v-list-item-title>
                <v-list-item-subtitle>{{ $store.getters["auth/getSession"] ? $store.getters["auth/getSession"].sKtpNo : "#N/A"}}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ $store.getters["auth/getSession"] ? $store.getters["auth/getSession"].sRole : "#N/A"}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click="logout">
              <v-list-item-avatar>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isMobileScreen: typeof window !== undefined && typeof window.screen !== undefined && window.screen.width < 600,
      loading: true,
      isOnline: false,
      showUserDetail: false,
      header: {
        title: process.env.VUE_APP_TITLE,
      },
      lastUpdate: "",
      drawer: true,
      menu_items: [
        {
          icon: "mdi-home", 
          img: require('@/assets/svg/dashboard/home.svg'),
          href: process.env.VUE_APP_BASE_URL + "/home",
          title: "Dashboard", 
          available: this.isActivePrivilege('dashboard', false),
          disabled: this.isActivePrivilege('dashboard', true),
          active: ['/','/home'].includes(window.location.pathname) ? 'is-active' : '',
          activeScope: true,
        },
        {
          icon: "mdi-users", 
          img: require('@/assets/svg/user/users.svg'), 
          title: "Users",
          href: process.env.VUE_APP_BASE_URL + "/users",
          available: this.isActivePrivilege('users', false),
          disabled: this.isActivePrivilege('users', true),
          active: ['/users'].includes(window.location.pathname) ? 'is-active' : '',
          activeScope: true,
        },
        {
          icon: "mdi-user", 
          img: require('@/assets/svg/user/voters.svg'), 
          title: "Voters", 
          href: process.env.VUE_APP_BASE_URL + "/voters",
          available: this.isActivePrivilege('voters', false),
          disabled: this.isActivePrivilege('voters', true),
          active: ['/voters'].includes(window.location.pathname) ? 'is-active' : '',
          activeScope: true,
        },
        {
          icon: "mdi-database", 
          img: require('@/assets/svg/user/voting.svg'),
          title: "Voting", 
          href: process.env.VUE_APP_BASE_URL + "/voting",
          available: this.isActivePrivilege('voting', false),
          disabled: this.isActivePrivilege('voting', true),
          active: ['/voting'].includes(window.location.pathname) ? 'is-active' : '',
          activeScope: true,
        },
      ]
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isSessionActive"];
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
      this.isOnline = window.navigator.onLine
    }, 1000);
    this.updateTime();
    this.setScheduler()
  },
  updated() {
    this.updateTime();
  },
  methods: {
    isActivePrivilege(page, _def){
      let listPrivilege = []
      if(page=='dashboard') listPrivilege = [...listPrivilege, ...['ADMIN','Admin Data','Korlap']]
      else if(page=='users') listPrivilege = [...listPrivilege, ...['ADMIN','Admin Data']]
      else if(page=='voters') listPrivilege = [...listPrivilege, ...['ADMIN','Korlap']]
      else if(page=='voting') listPrivilege = [...listPrivilege, ...['ADMIN','Admin Data','Korlap']]
      if(_def===false){
        return this.$store.getters["auth/getSession"] != null
          ? listPrivilege.includes(this.$store.getters["auth/getSession"].sRole) && this.$store.getters["auth/isSessionActive"]==true
          : _def
      }else{
        return this.$store.getters["auth/getSession"] != null
          ? !listPrivilege.includes(this.$store.getters["auth/getSession"].sRole) && this.$store.getters["auth/isSessionActive"]==true
          : _def
      }
    },
    updateTime() {
      let self = this;
      this.$nextTick(() => {
        let time = new Date();
        self.lastUpdate = `${("0" + time.getHours()).substr(-2)}:${("0" + time.getMinutes()).substr(-2)}`;
      });
    },
    onClick() {
      window.location.href = window.location.origin + process.env.VUE_APP_BASE_URL;
    },
    setScheduler(){
      let self = this
      setInterval(()=>{
        self.updateTime()
      }, 30000)
    },
    logout() {
      this.$store.dispatch("auth/logOut").then(() => {
        setTimeout(() => { window.location.href = window.location.origin + process.env.VUE_APP_BASE_URL; }, 300);
      }).catch(() => { 
        setTimeout(() => { window.location.href = window.location.origin + process.env.VUE_APP_BASE_URL; }, 300);
      });
    },
  },
};
</script>