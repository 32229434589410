import store from "@/store";
import router from "@/router";
import { encrypt } from "@/plugins/crypto";
import { buildQuery, http } from "@/plugins/http";

const errorResponse = (err) => {
    if (err && err.response && err.response.status === 400) {
        if (err.response.data && err.response.data.code === 420) {
            alert(`${err.response.data.message}. Please relogin!`);
            store.dispatch("auth/logOut").then(() => router.go("/login"));
        } else {
            throw err;
    }
    } else {
        if (err && err.response && err.response.status === 401) {
            alert("Session expired. Please relogin!");
            store.dispatch("auth/logOut").then(() => router.go("/login"));
        } else {
            throw err;
        }
    }
};
const setUUID=()=>{
    if (localStorage._1100_session) {
        try { 
            return JSON.parse(atob(localStorage._1100_session)).iUserID 
        } catch (e) { return null }
    } else { return null }
}

export default {
    getRequest(endpoint, params = {}) {
        params.sBy = setUUID()
        return http().get(endpoint + buildQuery(params))
        .then((response) => response.data)
        .catch((err) => { return errorResponse(err) });
    },
    postRequest(endpoint, payload, params = {}) {
        params.sBy = setUUID()
        payload = { __data: encrypt(payload) };
        return http().post(endpoint + buildQuery(params), payload)
        .then((response) => response.data)
        .catch((err) => { return errorResponse(err) });
    },
    putRequest(endpoint, payload, params = {}) {
        params.sBy = setUUID()
        payload = { __data: encrypt(payload) };
        return http().put(endpoint + buildQuery(params), payload)
        .then((response) => response.data)
        .catch((err) => { return errorResponse(err) });
    },
    putRequestForm(endpoint, payload, files, params = {}) {
        params.sBy = setUUID()
        let formData = new FormData();
        formData.append("__data", encrypt(payload));
        for (let i = 0; i < files.length; i++) {
            formData.append(files[i].id, files[i].file);
        }
        return http().put(endpoint + buildQuery(params), formData)
        .then((response) => response.data)
        .catch((err) => { return errorResponse(err) });
    },
    deleteRequest(endpoint, params = {}) {
        params.sBy = setUUID()
        return http().delete(endpoint + buildQuery(params))
        .then((response) => response.data)
        .catch((err) => { return errorResponse(err) });
    },
};
