<template>
  <div class="attribute-name">{{ text }}</div>
</template>

<script>
export default {
  props: [
    'text'
  ]
}
</script>

<style>
.attribute-name {
  font-weight: 500;
}
</style>
