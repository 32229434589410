<template>
  <v-main mx-auto overflow-hidden>
    <v-container fluid fill-height class="white lighten-3">
      <v-layout>
        <v-flex xs12 shrink>
          <v-fade-transition mode="out-in" :group="true">
            <v-breadcrumbs :key="'base-breadcrumb'" :items="breadcrumbList" divider="/" v-if="false"></v-breadcrumbs>
            <router-view :key="'base-content'" />
          </v-fade-transition>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'Quick Count'
    }
  },
  data () {
    return {
      breadcrumbList: []
    }
  },
  watch: {
    '$route' () {
      this.breadcrumbList = this.$route.meta.breadcrumb
    }
  },
  props: {
    source: String
  }
}
</script>