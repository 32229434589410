<template>
  <div>
    <!-- v-watermark="OptionConfig" -->
    <v-text-field single-line hide-details="auto" id="entryImage" v-model="label" :label="label" :required="required" :disabled="disabled" :readonly="readonly" class="entryImage"/>
    <div class="img-src" v-if="src!=null">
      <div class="">
        <img :src="src" :width="width" @click="previewImage" style="cursor: pointer;" :height="height">
      </div>
      <v-btn x-small class="img-delete px-0" v-if="src!=null && btnImgDelete" @click="deleteImage"><v-icon small>mdi-delete-empty</v-icon></v-btn>
    </div>
    <div v-else>
      <v-img :src="src" :width="width" :height="height" aspect-ratio="1" :class="classImg" @click="onClick" style="cursor: pointer;">
        <div class="">
          <p class="mb-0 text-center">
            <v-icon large style="cursor: pointer;">mdi-camera-outline</v-icon>
          </p>
          <p class="mb-0 text-center">
            <span>Upload Foto</span>
          </p>
        </div>
      </v-img>
    </div>
    <input id="fileImage" type="file" :accept="accept" :disabled="disabled" ref="fileImage" onclick="this.value=null;" @change="onFileChange" style="cursor:pointer!important;"/>
    <div class="ubah-foto py-2" v-if="src!=null && btnImgDelete">
      <p style="color: #C91C22; cursor: pointer; font-weight: bold;" @click="onClick" class="text-center mb-0">Ubah Foto</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
  export default {
    name: 'dotms_comp_entryimage',
    props: {
      value: {
        type: [Array, String]
      },
      accept: {
        type: String,
        default: 'image/*'
      },
      label: {
        type: String,
        default: ''
      },
      classImg: {
        type: String,
        default: 'grey lighten-2'
      },
      id: {
        type: Number,
        default: 0
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly:{
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      btnImgDelete: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: "245px"
      },
      height: {
        type: String,
        default: "245px"
      }
    },
    data() {
      return {
        OptionConfig:{
          // config:{
            // "bottomleft", "bottomright", "topleft", "topright", "center", "fill"
            mode: "bottomleft",
            textBaseline: "middle",
            font: "15px Arial",
            fillStyle: "white",
            content: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
            rotate: 10
          // }
        },
        lat:0, long:0,
        filename: '', 
        src: null,
      }
    },
    watch: {
      value(v) {
        this.filename = v
      }
    },
    mounted() {
      this.filename = this.value
      let self = this
      navigator.geolocation.getCurrentPosition((position)=>{
        self.lat = position.coords.latitude;
        self.long = position.coords.longitude;
      })
    },
    methods: {
      getFormData(files) {
        const forms = []
        for (const file of files) {
          const form = new FormData()
          form.append('data', file, file.name)
          forms.push(form)
        }
        return forms
      },
      onFocus() {
        if (!this.disabled) {
          this.$refs.fileImage.click()
        }
      },
      onClick(){
        this.$refs.fileImage.click()
      },
      calculateSize(img, maxWidth, maxHeight) {
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round(height * maxWidth / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round(width * maxHeight / height);
            height = maxHeight;
          }
        }
        return [width, height];
      },
      async onFileChange($event) {
        const party = this.$store.getters['auth/getUser']?this.$store.getters['auth/getUser'].sKtpNo:``
        const files = $event.target.files || $event.dataTransfer.files
        const form = this.getFormData(files)
        if (files) {
          if(files.length > 0){
            this.filename = [...files].map(file => file.name).join(', ')
          }else{
            this.filename = null
          }
        } else {
          this.filename = $event.target.value.split('\\').pop()
        }
        this.src = URL.createObjectURL(files[0])
        this.$emit('fullPath', this.$refs.fileImage.value)
        this.$emit('input', this.filename)
        // this.$emit('selectedFile', files[0], this.id)
        let self = this
        // COMPRESS file BLOB
        const img = new Image()
        img.src = this.src
        img.onerror=()=> URL.revokeObjectURL(this.src)
        img.onload=()=>{
          URL.revokeObjectURL(this.src);
          let [newWidth, newHeight] = self.calculateSize(img, 600, 800);
          let canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          let ctx = canvas.getContext("2d");
          // SET WATERMARK IMAGE
          // SET UKURAN
          ctx.clearRect(0, 0, newWidth, newHeight);
          ctx.drawImage(img, 0, 0, newWidth, newHeight-40);
          // SET FONT STYLE
          ctx.font="9px Ariel";
          ctx.textBaseline = "middle";
          ctx.textAlign = "left";
          // SET FONT COLOR
          ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
          ctx.fillText(`ID Report`, 3, newHeight-33);
          ctx.fillText(`Waktu Foto Diambil`, newWidth-90, newHeight-33);
          ctx.fillText(`Latitude`, 3, newHeight-13);
          ctx.fillText(`Longitude`, newWidth-90, newHeight-13);
          // SET FONT COLOR
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.fillText(`${party}${moment().format('YYMMDDHHmmss')}`, 3, newHeight-24);
          ctx.fillText(`${moment().format('DD MMM YYYY  HH:mm:ss')}`, newWidth-90, newHeight-24);
          ctx.fillText(`${self.lat}`, 3, newHeight-3);
          ctx.fillText(`${self.long}`, newWidth-90, newHeight-3);
          // DISPLAY NEW IMAGE
          canvas.toBlob((blob)=>{
            let url = ctx.canvas.toDataURL()
            self.src = url
            self.$emit('selectedFile', blob, self.id)
          },'image/jpg', 0.9)
        }
      },
      readableBytes(bytes) {
        const i = Math.floor(Math.log(bytes) / Math.log(1024)), sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
      },
      deleteImage(){
        this.src = null
        this.$emit('deletedFile', this.id)
      },
      previewImage(){
        this.$emit('previewImage', this.src, this.filename)
      }
    }
  }
</script>

<style>
  input[type=file] {
    position: absolute;
    left: -99999px;
    cursor: pointer !important;
  }
  i.v-icon.notranslate.mdi.mdi-folder-search.theme--light{
    cursor: pointer !important;
  }
  .entryImage{
    padding-top: 0px;
    align-items: center; 
    display: flex; 
    justify-content: center;
  }

  .img-delete .v-icon.v-icon {
    font-size: 25px !important;
    color: red;
  }

  .img-delete {
    position: relative;
    bottom: 45px !important;
    left: 130px !important;
    height: 30px !important;
    opacity: 0.9;
  }
</style>