import {getCookie} from '@/plugins/cookie'

export default {
    isSessionActive: () => {
        if (localStorage._1100_session) {
            try { 
                let sess=JSON.parse(atob(localStorage._1100_session))
                let sid = getCookie('connect.sid')
                return (['ADMIN','Admin Data','Korlap'].includes(sess['sRole']) && sess['session']!='' && sess['sKtpNo']!='' && sess['sName']!='')
            } catch (e) { return false }
        } else { return false }
    },
    getSession: () => {
        if (localStorage._1100_session) {
            try { return JSON.parse(atob(localStorage._1100_session)) } catch (e) { return null }
        } else { return null }
    },
}