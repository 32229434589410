<template>
  <div class="text-center" v-if="dialogType=='default'">
      <v-dialog v-model="flagShowD" width="500" :persistent="persistent">
        <v-card>
          <v-card-title class="title primary font-white" style="font-size: 0.25rem; font-weight: 500; letter-spacing: 0.0125em; padding: 2px 8px">{{ txtTittle }}</v-card-title>
          <v-divider></v-divider>
          <v-card-actions class="justify-center" style="padding:2px!important">
            <v-card-text v-show="flagBody" style="padding:4px!important">{{ txtBody }}</v-card-text>
            <v-spacer></v-spacer>
            <v-btn small color="success" @click="$emit('actionOk')" v-show="flagBtn1">{{ txtBtn1 }}</v-btn>
            <v-btn small color="error" @click="$emit('actionClose')" v-show="flagBtn2">{{ txtBtn2 }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center" v-else-if="dialogType=='custom-button'">
      <v-dialog v-model="flagShowD" :width="dialogWidth" :persistent="persistent">
        <v-card class="primary--text">
          <core-header :shown="true" bgcolor="prwhiteimary" :loading="false" column="x12">
          <template slot="content">
            <v-flex xs12 md8>
                <core-title color="primary" :title="txtTittle" :fontSize="16" />
            </v-flex>
            <v-flex xs12 md4>
              <div style="float:right">
                <v-btn fab small style="float:right;margin-left:5px" color="white" title="Close Dialog" @click="$emit('actionCancel')"><strong><v-icon>mdi-close</v-icon></strong></v-btn>
              </div>
            </v-flex>
          </template>
          </core-header>
          <v-card-text style="padding:0px 0px 2px 0px!important;">
            <v-layout>
                <v-row class="verified-asset-disposal px-10">
                  <v-col cols="10" sm="12" md="12" style="font-weight: bolder;">
                      <img src="@/assets/svg/confirm.svg" width="80" height="80" style="margin:auto;"/>
                      <p class="title" v-html="dialogTitle"></p>
                      <p class="desc" v-html="dialogDescription"></p>
                  </v-col>
                </v-row>
              </v-layout>
          </v-card-text>
          <v-card-actions class="action-start-service pb-6">
            <v-spacer></v-spacer>
            <v-flex xs12 md6 ml-3>
              <v-btn class="btn-batal" large @click="$emit('actionCancel')">{{ txtBtn1 }}</v-btn> 
            </v-flex>
            <v-flex xs12 md6 ml-3>
              <v-btn class="btn-iya" large @click="$emit('actionConfirm')">{{ txtBtn2 }} </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
export default {
  name: 'dotms_comp_dialog', 
  props: {
    txtTittle: {
      type: String,
      default: 'Request Timeout'
    },
    flagBtn1: {
      type: Boolean,
      default: true
    },
    txtBtn1:{
      type: String,
      default: 'Stay here'
    },
    flagBtn2: {
      type: Boolean,
      default: false
    },
    txtBtn2:{
      type: String,
      default: 'Back to login'
    },
    flagBody: {
      type: Boolean,
      default: false
    },
    txtBody:{
      type: String,
      default: 'Text Body'
    },
    flagShowD: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: String,
      default: "500px"
    },
    dialogBtnClose: {
      type: Boolean,
      default: true
    },
    dialogType: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: 'Confirmation Dialog.'
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    dialogDescription: {
      type: String,
      default: ''
    },
  }
}
</script>