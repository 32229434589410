<template>
  <div v-on:dblclick="bodyClick">
    <v-flex :class="`${column}`" v-show="shown">
      <v-progress-linear v-show="loading" slot="progress" :color="$store.getters['app/color']('e')" indeterminate style="margin:0!important;"></v-progress-linear>
      <div id="body" :class="`${bgcolor} py-0 px-0`" :style="`${styles}padding-bottom: 0px!important; background-image:url(${bgimage}); background-position: center; background-repeat: no-repeat; position:relative;`">
        <slot name="content" />
      </div>
    </v-flex>
    <div class="text-center">
      <core-alert :flagShow="msgshown" v-on:funcflagShow="funcflagShow" :txtType="msgtype" :txtMsgAlert="msg" />
      <core-dialog :flagShowD="shownd" :txtTittle="txtTittle" :flagBody="flagBody" :txtBody="txtBody" :txtBtn1="txtBtn1" :txtBtn2="txtBtn2" :flagBtn1="flagBtn1" :flagBtn2="flagBtn2"
        v-on:actionClose="actionBtn1" v-on:actionOk="actionBtn2"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dotms_comp_body',
  props: {
    shown: { type: Boolean, default: true },
    column: { type: String, default: 'x12' },
    loading: { type: Boolean, default: true }, 
    styles:{ type: String, default: '' },
    bgimage:{ type: String, default: '' },
    bgcolor: { type: String, default: 'primary' },

    msgshown: { type: Boolean, default: false },
    msgtype: { type: String, default: 'success' },
    msg: { type: String, default: 'Message' },

    shownd:{ type: Boolean, default: false },
    txtTittle:{ type: String, default: 'Request Timeout' },
    flagBody:{ type: Boolean, default: false },
    txtBody:{ type: String, default: '' },
    txtBtn1:{ type: String, default: 'Back to login' },
    txtBtn2:{ type: String, default: 'Stay Here' },
    flagBtn1:{ type: Boolean, default: true },
    flagBtn2:{ type: Boolean, default: false },
  },
  methods: {
    funcflagShow(){
      this.$emit('funcmsgshown')
    },
    actionBtn1(){
      this.$emit('funcshown')
    },
    actionBtn2(){
      this.$emit('funcshownd')
    },
    bodyClick(){
      this.$emit('bodyClick')
    },
  },
}
</script>
<style scoped>
#body{
  /* display: flex; */
  align-items: center;
  justify-content: center;
}
</style>