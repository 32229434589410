<template>
  <v-row>
    <v-col cols="6">
      <!-- TODO: DATEPICKER AMBIL KOMPONEN ENTRY NESTED APP PORTAL WEB LATEST -->
      <core-entry-field-nested-label-dyn-type1
        :type="switch1 ? `datepicker` : `text`"
        :isRequired="true"
        v-model="date"
        disabled
        @input="onDateConfirm"
        :rightIcon="!switch1 ? `mdi-calendar` : ``"
        :iconColor="`inherit`"
        :label="dateLabel"
      />
    </v-col>
    <v-col cols="6">
      <v-switch
        v-model="switch1"
        :label="switchLabel"
        color="black"
        @change="onChangeSwitch"
      ></v-switch>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
export default {
  name: "dotms_comp_filter_select",

  props: {
    switchLabel: {
      type: String,
      default: "MTD View",
    },
  },

  data() {
    return {
      menu: false,
      switch1: false,
      date: moment().format("YYYY-MM-DD"),
      dateLabel: "Current Date",
    };
  },

  methods: {
    onChangeSwitch(val) {
      if (!val) {
        this.date = moment().format("YYYY-MM-DD");
        this.$emit("onChangeSwitchToFalse");
      } else {
        this.$emit("onChangeSwitchToTrue", this.date);
      }
      this.dateLabel = val === true ? "MTD Date" : "Current Date";
    },

    onDateConfirm(val) {
      if (this.switch1) {
        this.$emit("onDateConfirm", val);
      }
      this.menu = false;
    },
  },
};
</script>