import Api from '@/services/ap/AP_ENDPOINT-GW'
import {getCookie} from '@/plugins/cookie'
import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from './mutation-types'

export default {
  getSession({commit}){
    let session = {active: false, con:null, key:null}    
    session.con = getCookie('connect.sid')
    session.uuid = localStorage.getItem('_1100_session')
    if(session.con!=null && session.uuid!=null){
       session.active=true
     }else{
       session.active=false
     }
    return session;
  },
  syncSession({commit}, userID) {
    return Api.postRequest(`/user/${userID}`).then(res => {
      if(res.status) commit(LOGIN_SUCCESS, res.data)
      else commit(LOGIN_FAILED)
      return res
    })
  },
  logIn({commit}, credentials) {
    commit(LOGIN)
    return Api.postRequest(`/login`, credentials).then(res => {
      if(res.status) commit(LOGIN_SUCCESS, res.data)
      else commit(LOGIN_FAILED)
      return res
    })
  },
  logOut({commit}) {
    return Api.getRequest(`/logout`).then(res=>commit(LOGOUT)).catch(err=>commit(LOGOUT))
  },
}
