<template>
  <v-card class="mx-auto flex card-widget" shaped>
    <v-card class="mx-auto flex" :disabled="disabled" shaped :style="`cursor:pointer;display:flex;background:${bgColor};${styles}`" @click="action">
      <div class="widget-content">
        <div class="widget-icon" :class="titleRight ? 'mb-4' : ''">
          <v-row>
            <v-flex xs12 md3>
             <v-avatar v-if="img!=''"><v-img :src="img"/></v-avatar>
             <v-avatar size="65" tile color="transparent" v-else><v-icon :color="bgColor">{{icon}}</v-icon></v-avatar>
            </v-flex>
            <v-flex xs12 md9 v-if="titleRight">
              <v-card-title :style="`color:${textColor}!important;${textStyles}; justify-content: center;`">
                {{titleRight}}
              </v-card-title>
            </v-flex>
            <v-flex xs12 md9 v-if="headcountRight">
              <v-card-title :style="`justify-content: right; font-size: 40px !important; color:#37373F !important`">
                {{headcountRight}}
              </v-card-title>
            </v-flex>
          </v-row>
        </div>
        <v-card-title :style="`color:${textColor}!important;${textStyles}`">
          {{title ? title : ''}}
        </v-card-title>
        <v-card-subtitle>
          <span class="unit">{{headcount}}</span>
          <span class="sub-unit" v-if="subheadcount!=''">{{subheadcount}}</span>
        </v-card-subtitle>
      </div>
    </v-card>
    <div class="widget-action" v-if="addInfo.length>0">
      <div class="widget-action-item" @click="action1">
        <span class="dot-circle" v-if="addInfo[0].enableColorSign" :style="`background:${addInfo[0].color?addInfo[0].color:bgColor} !important`"></span>
        <span v-if="addInfo[0].text" :style="`color: ${addInfo[0].color}`" :color="addInfo[0].color?addInfo[0].color:bgColor">{{ addInfo[0].text }}</span>
        <span v-if="addInfo[0].value" :color="addInfo[0].color?addInfo[0].color:bgColor">{{ addInfo[0].value }}</span>
      </div>
      <div class="widget-action-item" @click="action2" v-if="addInfo.length>1">
        <span class="dot-circle" v-if="addInfo[1].enableColorSign" :style="`background:${addInfo[1].color?addInfo[1].color:bgColor} !important`"></span>
        <span v-if="addInfo[1].text" :style="`color: ${addInfo[1].color}`" :color="addInfo[1].color?addInfo[1].color:bgColor">{{ addInfo[1].text }}</span>
        <span v-if="addInfo[1].value" :color="addInfo[1].color?addInfo[1].color:bgColor">{{ addInfo[1].value }}</span>
      </div>
      <div class="widget-action-item" @click="action3" v-if="addInfo.length>2">
        <span class="dot-circle" v-if="addInfo[2].enableColorSign" :style="`background:${addInfo[2].color?addInfo[2].color:bgColor} !important`"></span>
        <span v-if="addInfo[2].text" :style="`color: ${addInfo[2].color}`" :color="addInfo[2].color?addInfo[2].color:bgColor">{{ addInfo[2].text }}</span>
        <span v-if="addInfo[2].value" :color="addInfo[2].color?addInfo[2].color:bgColor">{{ addInfo[2].value }}</span>
      </div>
    </div>
  </v-card>
</template>
  <script>
  export default {
    name: 'dotms_comp_widget',
    props: {
      isNew: {
        type: Boolean,
        default: false
      },
      cssClass: {
        type: String,
        default: ''
      },
      bordered: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      addInfo: {
        type: Array,
        default:()=> []
      },
      styles: {
        type: String,
        default: ''
      },
      bgColor: {
        type: String,
        default: 'white'
      },
      textColor: {
        type: String,
        default: '#000'
      },
      height: {
        type: String,
        default: 'auto'
      },
      textStyles: {
        type: String,
        default: ''
      },
      headcount: {
        type: String,
        default: ''
      },
      subheadcount: {
        type: String,
        default: ''
      },
      headcountRight: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      titleRight: {
        type: String,
        default: ''
      },
      titleLeft: {
        type: String,
        default: ''
      },
      img: {
        type: String,
        default: ''
      },
      imgRight: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      marginClass: {
        type: String,
        default: ''
      }
    },
    methods:{
      action(){
        this.$emit('action');
      },
      action1(){
        this.$emit('action1');
      },
      action2(){
        this.$emit('action2');
      },
      action3(){
        this.$emit('action3');
      },
      action4(){
        this.$emit('action4');
      },
      subaction(){
        this.$emit('subaction');
      }
    }
  }
  </script>
  <style scoped>
  .addInfo{
    position: absolute;
    right: 20px;
    top: 60px;
  }
  .card-widget {
    display:flex;
    background:#E5E5E5;
    margin:0px;
    border-radius:6px;
  }
  .right-position-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .widget-action-item.resize > * + * {
      font-size: 26px !important;
      font-weight: 700 !important;
  }
  .unit.resize {
    font-size: 24px !important
  }
  </style>