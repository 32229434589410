
<!-- 
  Properties:

  - tblHeader
    type: Object Array
    default: []
     Item properties:
      1. text: String
          Label displayed on th
      2. value: String
          object name of item
      3. sortable?: Boolean
          default true
      4. align?: String (start|center|end)
          default: start
      5. width?: string | number
      6. class?: string | string[],
          append class for header item
      7. cellClass?: string | string[],
          append class for cell item

  - items
    type: Object Array
    default: []
  
  - backgroundTable
    type: String of color
    default: white
  
  - backgroundHeader
    type: String of color
    default: backgroundTable
  
  - backgroundBody
    type: String of color
    default: backgroundTable

  - headerTextColor
    type: String of color
    default: black

  - bodyTextColor
    type: String of color
    default: black

  - headerClass
    type: String | String Array
    default: null
    append class for each header cell

  - cellClass
    type: String | String Array
    default: null
    append class for each row cell

  - show-select
    type: Boolean
    default: false
    showing row selections

  - single-select
    type: Boolean
    default: false
    aditional prop for show-select for allowing only one selection

  - show-expand
    type: Boolean
    default: false
    showing additional content for each row

  - single-expand
    type: Boolean
    default: false
    aditional prop for show-expand. 
    when expanding a row, another expanded item will be collapsed 

  -->
  <template>
    <v-data-table
        :fixed-header="fixedHeader"
        :height="height"
        dense
        v-model="selectedrow"
        @input="$emit('input', selectedrow)"
        :headers="tblHeader"
        :item-key="itemKey"
        :items="datas"
        :search="search"
        :class="backgroundTable"
        :style="cssProps"
        :show-select="showSelect"
        :single-select="singleSelect"
        :show-expand="showExpand"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :multi-sort="multiSort"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :items-per-page="rowPerPage"
        :disable-pagination="disablePaging"
        :hide-default-footer="disablePaging"
        :mobile-breakpoint="0"
        :no-data-text="noDataText"
        :no-results-text="noResultsText"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        >
        <template v-slot:item="{ item, expand, isExpanded, isSelected, select  }">
          <!-- <tr :class="[bodyTextColor+'--text', backgroundBody, item.classBu && item.classBu == 'white' ? '' : item.classBu, item.classBm && item.classBm == 'white' ? '' : item.classBm, item.bu == true]"> -->
          <tr :class="[bodyTextColor+'--text', backgroundBody, item.classBu != 'white' ? item.classBu : item.classBm != 'white' ? item.classBm : '']">
            <td v-if="showSelect">
              <v-simple-checkbox :readonly="item.disabled" :disabled="item.disabled" :color="bodyTextColor" :value="isSelected" :ripple="false" @input="select($event)"></v-simple-checkbox>
            </td>
            <td :class="item._color?item._color : !!header.cellClass ? Array.isArray(header.cellClass) ? header.cellClass.join(' ') : header.cellClass: ''" v-bind:style="tdStyle" v-for="(header, idxheader) in tblHeader" :key="idxheader">
                <v-icon v-if="header.value == 'data-table-expand'" @click="expand(!isExpanded)" class="v-data-table__expand-icon v-icon--link">mdi-chevron-down</v-icon>
                <slot v-else-if="!!$scopedSlots['item.'+header.value]" :name="'item.'+header.value"  v-bind:item="item"></slot>
                <span v-else-if="item[header.value]&&item[header.value].toString().split('|').length>1" :style="header.align&&header.align=='center'?'text-align:center':''">
                  <div>{{ item[header.value].toString().split('|')[0] }}</div>
                  <div style="font-size:10px!important">{{ item[header.value].toString().split('|')[1] }}</div>
                </span>
                <div :class="`${item[header.value]}` == 'Kurang' ? 'detail-kurang' : `${item[header.value]}` == 'Service' ? 'detail-service' : ''" v-else :style="header.align&&header.align=='center'?'text-align:center':''">{{ item[header.value] }}</div>
            </td>
          </tr>
        </template>
        <template  v-if="!!$scopedSlots['expanded-item']" v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <slot name="expanded-item" v-bind:headers="headers" v-bind:item="item"></slot>
          </td>
        </template>
    </v-data-table>
</template>
<script>
export default {
  name: 'dotms_comp_table_grid',
  props: {
    disablePaging:{
      type: Boolean,
      value: false
    },
    gridColor: {
      type: String,
      default: '#CCC'
    },
    backgroundTable: {
      type: String,
      default: 'white'
    },
    backgroundHeader: {
      type: String,
      default: '#c8001d'
    },
    backgroundBody: {
      type: String,
      default: 'white'
    },
    headerTextColor: {
      type: String,
      default: 'white'
    },
    bodyTextColor: {
      type: String,
      default: 'black'
    },
    headerClass: {
      type: [String, Array],
      default: null
    },
    cellClass: {
      type: [String, Array],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    tblHeader: {
      type: Array,
      default: () => []
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    singleExpand: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    singleSelect: {
      type: Boolean,
      default: false
    },
    searchby:{
      type: String,
      default: 'ALL'
    },
    search: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    multiSort: {
      type: Boolean,
      default: false
    },
    itemKey: {
      type: String,
      default: 'frn'
    },
    selected: {
      type: Array,
      default: () => []
    },
    rowPerPage: {
      type: Number,
      default: 15
    },
    includeColumnSearch: {
      type: Array,
      default: () => []
    },
    defData: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: '100vh'
    },
    fixedHeader: {
      type: Boolean,
      default: true
    },
    noDataText: {
      type: String,
      default: 'Data not available'
    },
    noResultsText: {
      type: String,
      default: 'Tidak terdapat data yang sesuai kata kunci pencarian'
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: true
    },
    theadClass: {
      type: String,
      default: ""
    },
    actionExpandedCheckbox: {
      type: Function,
      default: () => this.onExpandedCheckbox()
    }
  },
  data() {
    return {
        selectedrow: this.selected?this.selected:[],
        expanded: this.defData?this.defData:[],
        datas: this.items?this.items:[], 
        thStyle: {
          "border-bottom": 'thin solid '+this.gridColor,
        },
        tdStyle: {
          "border-bottom": 'thin solid '+this.gridColor,
        }
    }
  },
  created() {
      this.tblHeader.map(this.updatedStyle)
      // this.selected = this.value
  },
  computed: {
    cssProps() {
      return {
        '--table-grid-color': this.gridColor,
        '--header-bg-color': this.backgroundHeader,
        '--header-text-color': this.headerTextColor,
      }
    },
    hasActionSlots() {
        return !!this.$slots.item && !!this.$slots.item.actions
    }
  },
  watch:{
    items(val){
      this.datas = val
    },
    selected(val){
      this.selectedrow = val
    },
    selectedrow(val){
      this.$emit('selectedRow', val)
    }
  },
  methods: {
    newFilterCol(a,item,search){
      try{
        let result = []
        a.map(x=>{
          // console.log('x',x,item[x],item)
          if(item[x].length>0){
            item[x].filter(obj=>{
              // console.log('obj',obj)
              Object.keys(obj).some(key=>{
                result.push(obj[key]&&obj[key].toString().toLowerCase().includes(search.toString().toLowerCase()))
              })
            }) 
          }else{
            Object.keys(item[x]).some(key=>{
              // console.log('key',item[x][key])
              if(item[x][key].length>0){
                item[x][key].filter(obj2=>{
                  // console.log('obj2',obj2)
                  Object.keys(obj2).some(key2=>{
                    // if(obj2[key2]) console.log('obj2[key2]...',obj2,key2,obj2[key2].toString().toLowerCase(),search.toString().toLowerCase(),obj2[key2].toString().toLowerCase().includes(search.toString().toLowerCase()))
                    result.push((obj2[key2]&&obj2[key2].toString().toLowerCase().includes(search.toString().toLowerCase())))
                  })
                }) 
              }
            })
          }
        })
        // console.log('result',result.filter(x=>x===true).length>0)
        return result.filter(x=>x===true).length>0
      }catch(e){
        console.log(e)
        return false
      }
    },
    customSort(items, index, isDesc){
      items.sort((a, b) => {
        if (index[0] == "iStatusOpname") {
          let ar = a[index[0]] === undefined ? -1 : a[index[0]]
          let br = b[index[0]] === undefined ? -1 : b[index[0]]
          if (!isDesc[0]) {
            return (ar - br);
          } else {
            return (br - ar);
          }
        } else if (!(isNaN(a[index[0]]))) {
          if (!isDesc[0]) {
            return (a[index[0]] - b[index[0]]);
          } else {
            return (b[index[0]] - a[index[0]]);
          }
        } else {
          if (!isDesc[0]) {
            return (a[index[0]] < b[index[0]]) ? -1 : 1;
          } else {
            return (b[index[0]] < a[index[0]]) ? -1 : 1;
          }
        }
      });
      return items;
    },
    customFilter(value, search, item) {
      // console.log(this.searchby, search);
      // console.log(value, search, item);
      if (search) {
        if (search.toString().search(';') > -1) {
          let attr = search.toString().split(';')
          // console.log(value, search, item, attr);
          if (attr.length === 2) {
            if (item[attr[0].toString().split(':')[0].toString()] !== undefined || item[attr[1].toString().split(':')[0].toString()] !== undefined) {
              // console.log(item[attr[0].toString().split(':')[0].toString()], item[attr[1].toString().split(':')[0].toString()]);
              if (item[attr[0].toString().split(':')[0].toString()]) {
                return true
              } else if (item[attr[1].toString().split(':')[0].toString()]) {
                return true
              } else {
                return false
              }
            } else {
              // console.log("tidak pas");
              return false
            }
          } else {
            return false
          }
        } else if(search.toString().split(':').length==2){
          // console.log(item[search.toString().split(':')[0].toString()].toString().toLowerCase().indexOf(search.toString().split(':')[1].toString().toLowerCase()));
          return item[search.toString().split(':')[0].toString()]!==undefined ? item[search.toString().split(':')[0].toString()].toString().toLowerCase().indexOf(search.toString().split(':')[1].toString().toLowerCase()) !== -1 : false
        } else{
          // console.log('...',this.includeColumnSearch && this.includeColumnSearch.length>0)
          if(this.includeColumnSearch && this.includeColumnSearch.length > 0){
            let filterChild = this.newFilterCol(this.includeColumnSearch,item,search)
            return (value != null && search != null && typeof value === 'string' && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (this.searchby==''?false:item[this.searchby].toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1)  || filterChild
          }else{
            if (item[this.searchby]) {
              // console.log(value,'---',search,'===',item[this.searchby], this.searchby)
              if (value != null) {
                return value != null && search != null && typeof value === 'string' 
                && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
                && (this.searchby==''?true:item[this.searchby].toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1)
              } else {
                return search != null && (this.searchby==''?true:item[this.searchby].toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1)
              }
            } else {
              // console.log(value,'---', search,'===', item[this.searchby], this.searchby)
              if(!this.searchby) {
                return value != null && search != null && typeof value === 'string' && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
              } else {
                return value != null && search != null && typeof value === 'string' && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 && this.searchby
              }
            }
          }
        }
      } else {
        if(!this.searchby) {
          return value != null && search != null && typeof value === 'string' && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        }
      }
    },
    hasSlot (name = 'default') {
        return !!this.$slots[ name ] || !!this.$scopedSlots[ name ];
    },
    updatedStyle(header) {
      let headerClasses = Array.isArray(header.class)? header.class: header.class != undefined ? [header.class] : []
      
      if(this.headerClass) {
        if(Array.isArray(this.headerClass)) 
          headerClasses = this._.union(headerClasses, this.headerClass)
        else 
          headerClasses.push(this.headerClass)
      }
      if(headerClasses.length > 0)
        header.class = headerClasses
      // cell classes
      let cellClasses = Array.isArray(header.cellClass)? header.cellClass: header.cellClass != undefined ? [header.cellClass] : []
      if(this.cellClass) {
        if(Array.isArray(this.cellClass)) 
          cellClasses = this._.union(cellClasses, this.cellClass)
        else 
          cellClasses.push(this.cellClass)
      }
      if(cellClasses.length > 0)
        header.cellClass = cellClasses
    },
    onExpandedCheckbox($event) {
      this.$emit('actionExpandedCheckbox');
    }
  }
}
</script>
<style>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: thin solid var(--table-grid-color) !important;
    background-color: var(--header-bg-color) !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    color: var(--header-text-color) !important;
}
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: var(--header-text-color) !important;
}
.v-data-table__checkbox > .v-icon.v-icon {
  color: var(--header-text-color);
}
.wd-100{
  width: 100%;
}
</style>