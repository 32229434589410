<template>
  <v-flex :class="`${column} ${toolbar?'v-toolbar':''}`" v-show="shown" :style="styles"  >
    <div :class="`${bgcolor} py-2 px-2 v-toolbar__content v-toolbar__content2`" :style="styles">
      <slot name="content" />
    </div>
  </v-flex>
</template>

<script>
export default {
  name: 'dotms_comp_header',
  props: {
    toolbar: {
      type: Boolean,
      default: true
    },
    bgcolor: {
      type: String,
      default: 'primary'
    },
    shown: {
      type: Boolean,
      default: true
    },
    column: {
      type: String,
      default: 'x12'
    },
    //tambahan
    styles: {
      type: String,
      default: ''
    },
  },
}
</script>