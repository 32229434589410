<template>
  <v-flex :class="`${column}`" v-show="shown">
    <slot name="content" />
    <div :class="`footer ${bgcolor}`">
      <v-card class="elevation-12 pa-2 dark white--text d-flex align-center copyright">
          <v-layout justify-center> 
            <span v-html="title"></span>
          </v-layout>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import moment from 'moment';
export default {
  name: 'dotms_comp_footer',
  props: {
    bgcolor: {
      type: String,
      default: ''
    },
    shown: {
      type: Boolean,
      default: true
    },
    column: {
      type: String,
      default: 'x12'
    },
    title: {
      type: String,
      default: `Copyright [AHP] &copy; ${moment().format('Y')}. All rights reserved`
    }
  },
}
</script>