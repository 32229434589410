<template>
  <v-snackbar v-model="flagShow" :timeout="3000" :color="txtType" :multi-line="true" shaped>
    {{ txtMsgAlert }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="$emit('funcflagShow')">{{ lBtn }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'dotms_comp_alert', 
  props: {
    txtType: {
      type: String,
      default: 'success'
    },
    txtMsgAlert: {
      type: String,
      default: 'Message'
    },
    flagShow: {
      type: Boolean,
      default: false
    },
    lBtn:{
      type: String,
      default: 'Close'
    }
  }
}
</script>