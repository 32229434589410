<template>
  <v-autocomplete
      v-model="value"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      cache-items
      class="mx-4"
      ref="entrySearch"
      flat
      hide-no-data
      hide-details
      :label="label"
      solo-inverted
    ></v-autocomplete>
</template>

<script>
  export default {
    name: 'dotms_comp_entrysearch',
    props: {
      value: {
        type: [Number, String],
        default: null
      },
      loading:{
        type: Boolean,
        default: false,
      },
      items:{
          type: Array,
          default: []
      },
      label: {
        type: String,
        default: 'Search ...'
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        filename: ''
      }
    },
    watch: {
      value(v) {
        this.filename = v
      },
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
    mounted() {
      this.filename = this.value
    },
    methods: {
      querySelections (v) {
        this.loading = true
        setTimeout(() => {
          this.items = this.states.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          })
          // this.$emit('input', this.filename)
          this.loading = false
        }, 500)
      },
      onFocus() {
        if (!this.disabled) {
          this.$refs.entrySearch.click()
        }
      },
    }
  }
</script>