<template>
  <v-card
    class="mx-auto flex"
    shaped
    style="display: flex; background: #e5e5e5; margin: 6px; border-radius: 6px"
  >
    <v-card
      class="mx-auto flex"
      shaped
      :style="`cursor:pointer;display:flex;background:${bgColor};${styles};`"
      @click="action"
    >
      <div style="display: grid">
        <v-card-title>
          <v-row dense no-gutters>
            <v-col
              :style="`color:${textColor}!important;font-size:2rem;${textStyles}; display: contents;`"
            >
              {{ headcount }}
            </v-col>
            <v-col
              v-if="subheadcount != ''"
              style="
                font-size: 1rem !important;
                word-break: break-all;
                margin-right: 30px;
                margin-left: 20px;
                color: #ffffff;
              "
            >
              {{ subheadcount }}
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-subtitle
          v-if="title"
          :style="`color:${textColor}!important; padding-right: 0px;`"
          >{{ title }}</v-card-subtitle
        >
      </div>

      <div style="position: absolute; top: 0; right: 0; background: white">
        <v-avatar v-if="img != ''"
          ><v-img :src="`@/assets/img/${img}`"
        /></v-avatar>
        <v-avatar x-small v-else
          ><v-icon :color="bgColor">{{ icon }}</v-icon>
        </v-avatar>

        <v-badge
          v-if="badge"
          bordered
          overlap
          :color="badge.bgColor"
          :content="badge.value"
          :value="badge.value ? badge.value : ``"
          :style="badge ? `position: absolute` : ``"
        ></v-badge>
      </div>
    </v-card>

    <div v-if="addInfo.length > 0" :style="`padding-right:20px`">
      <v-row>
        <v-col class="custom-class-widget3-col">
          <core-child-dashboard
            :style_="addInfo[0].style"
            :badgeIcon="addInfo[0].badgeIcon"
            :badgeIconColor="addInfo[0].badgeIconColor"
            :badgeColor="addInfo[0].badgeColor"
            :badgeValue="addInfo[0].value"
            :btnText="addInfo[0].text"
            :btnColor="addInfo[0].bgColor"
            v-if="addInfo.length > 0 && !addInfo[0].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[1].style"
            :badgeIcon="addInfo[1].badgeIcon"
            :badgeIconColor="addInfo[1].badgeIconColor"
            :badgeColor="addInfo[1].badgeColor"
            :badgeValue="addInfo[1].value"
            :btnText="addInfo[1].text"
            :btnColor="addInfo[1].bgColor"
            v-if="addInfo.length > 1 && !addInfo[1].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[2].style"
            :badgeIcon="addInfo[2].badgeIcon"
            :badgeIconColor="addInfo[2].badgeIconColor"
            :badgeColor="addInfo[2].badgeColor"
            :badgeValue="addInfo[2].value"
            :btnText="addInfo[2].text"
            :btnColor="addInfo[2].bgColor"
            v-if="addInfo.length > 2 && !addInfo[2].isEmpty"
          ></core-child-dashboard>
        </v-col>
        <v-col class="custom-class-widget3-col">
          <core-child-dashboard
            :style_="addInfo[3].style"
            :badgeIcon="addInfo[3].badgeIcon"
            :badgeIconColor="addInfo[3].badgeIconColor"
            :badgeColor="addInfo[3].badgeColor"
            :badgeValue="addInfo[3].value"
            :btnText="addInfo[3].text"
            :btnColor="addInfo[3].bgColor"
            v-if="addInfo.length > 3 && !addInfo[3].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[4].style"
            :badgeIcon="addInfo[4].badgeIcon"
            :badgeIconColor="addInfo[4].badgeIconColor"
            :badgeColor="addInfo[4].badgeColor"
            :badgeValue="addInfo[4].value"
            :btnText="addInfo[4].text"
            :btnColor="addInfo[4].bgColor"
            v-if="addInfo.length > 4 && !addInfo[4].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[5].style"
            :badgeIcon="addInfo[5].badgeIcon"
            :badgeIconColor="addInfo[5].badgeIconColor"
            :badgeColor="addInfo[5].badgeColor"
            :badgeValue="addInfo[5].value"
            :btnText="addInfo[5].text"
            :btnColor="addInfo[5].bgColor"
            v-if="addInfo.length > 5 && !addInfo[5].isEmpty"
          ></core-child-dashboard>
        </v-col>

        <v-col class="custom-class-widget3-col">
          <core-child-dashboard
            :style_="addInfo[6].style"
            :badgeIcon="addInfo[6].badgeIcon"
            :badgeIconColor="addInfo[6].badgeIconColor"
            :badgeColor="addInfo[6].badgeColor"
            :badgeValue="addInfo[6].value"
            :btnText="addInfo[6].text"
            :btnColor="addInfo[6].bgColor"
            v-if="addInfo.length > 6 && !addInfo[6].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[7].style"
            :badgeIcon="addInfo[7].badgeIcon"
            :badgeIconColor="addInfo[7].badgeIconColor"
            :badgeColor="addInfo[7].badgeColor"
            :badgeValue="addInfo[7].value"
            :btnText="addInfo[7].text"
            :btnColor="addInfo[7].bgColor"
            v-if="addInfo.length > 7 && !addInfo[7].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[8].style"
            :badgeIcon="addInfo[8].badgeIcon"
            :badgeIconColor="addInfo[8].badgeIconColor"
            :badgeColor="addInfo[8].badgeColor"
            :badgeValue="addInfo[8].value"
            :btnText="addInfo[8].text"
            :btnColor="addInfo[8].bgColor"
            v-if="addInfo.length > 8 && !addInfo[8].isEmpty"
          ></core-child-dashboard>
        </v-col>

        <v-col class="custom-class-widget3-col">
          <core-child-dashboard
            :style_="addInfo[9].style"
            :badgeIcon="addInfo[9].badgeIcon"
            :badgeIconColor="addInfo[9].badgeIconColor"
            :badgeColor="addInfo[9].badgeColor"
            :badgeValue="addInfo[9].value"
            :btnText="addInfo[9].text"
            :btnColor="addInfo[9].bgColor"
            v-if="addInfo.length > 9 && !addInfo[9].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[10].style"
            :badgeIcon="addInfo[10].badgeIcon"
            :badgeIconColor="addInfo[10].badgeIconColor"
            :badgeColor="addInfo[10].badgeColor"
            :badgeValue="addInfo[10].value"
            :btnText="addInfo[10].text"
            :btnColor="addInfo[10].bgColor"
            v-if="addInfo.length > 10 && !addInfo[10].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[11].style"
            :badgeIcon="addInfo[11].badgeIcon"
            :badgeIconColor="addInfo[11].badgeIconColor"
            :badgeColor="addInfo[11].badgeColor"
            :badgeValue="addInfo[11].value"
            :btnText="addInfo[11].text"
            :btnColor="addInfo[11].bgColor"
            v-if="addInfo.length > 11 && !addInfo[11].isEmpty"
          ></core-child-dashboard>
        </v-col>

        <v-col class="custom-class-widget3-col">
          <core-child-dashboard
            :style_="addInfo[12].style"
            :badgeIcon="addInfo[12].badgeIcon"
            :badgeIconColor="addInfo[12].badgeIconColor"
            :badgeColor="addInfo[12].badgeColor"
            :badgeValue="addInfo[12].value"
            :btnText="addInfo[12].text"
            :btnColor="addInfo[12].bgColor"
            v-if="addInfo.length > 12 && !addInfo[12].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[13].style"
            :badgeIcon="addInfo[13].badgeIcon"
            :badgeIconColor="addInfo[13].badgeIconColor"
            :badgeColor="addInfo[13].badgeColor"
            :badgeValue="addInfo[13].value"
            :btnText="addInfo[13].text"
            :btnColor="addInfo[13].bgColor"
            v-if="addInfo.length > 13 && !addInfo[13].isEmpty"
          ></core-child-dashboard>

          <core-child-dashboard
            :style_="addInfo[14].style"
            :badgeIcon="addInfo[14].badgeIcon"
            :badgeIconColor="addInfo[14].badgeIconColor"
            :badgeColor="addInfo[14].badgeColor"
            :badgeValue="addInfo[14].value"
            :btnText="addInfo[14].text"
            :btnColor="addInfo[14].bgColor"
            v-if="addInfo.length > 14 && !addInfo[14].isEmpty"
          ></core-child-dashboard>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "dotms_comp_widget",
  props: {
    addInfo: {
      type: Array,
      default: () => [],
    },
    badge: {
      type: Object,
      default: () => {},
    },
    styles: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "white",
    },
    bgColor1: {
      type: String,
      default: "#A4A4A4",
    },
    bgColor2: {
      type: String,
      default: "#A4A4A4",
    },
    textColor: {
      type: String,
      default: "",
    },
    textStyles: {
      type: String,
      default: "",
    },
    headcount: {
      type: String,
      default: "",
    },
    subheadcount: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  methods: {
    action() {
      this.$emit("action");
    },
    action1() {
      this.$emit("action1");
    },
    action2() {
      this.$emit("action2");
    },
    action3() {
      this.$emit("action3");
    },
    action4() {
      this.$emit("action4");
    },
    subaction() {
      this.$emit("subaction");
    },
  },
};
</script>
<style scoped>
.addInfo {
  position: absolute;
  right: 20px;
  top: 60px;
}

.custom-class-widget3-col {
  margin-bottom: 6px !important;
}
</style>