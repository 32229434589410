import { render, staticRenderFns } from "./Widget4.vue?vue&type=template&id=1238dfab&scoped=true"
import script from "./Widget4.vue?vue&type=script&lang=js"
export * from "./Widget4.vue?vue&type=script&lang=js"
import style0 from "./Widget4.vue?vue&type=style&index=0&id=1238dfab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1238dfab",
  null
  
)

export default component.exports