<template>
  <v-dialog
    v-model="fieldModelValue"
    :max-width="maxWidth ? maxWidth : '640'"
  >
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>

      <v-card-text>
        <slot name="content" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="showButton"
          color="green darken-1"
          flat="flat"
          @click="reorder(job)"
        >
          Reorder
        </v-btn>

        <v-btn
          v-if="showButton && showCancel"
          color="green darken-1"
          flat="flat"
          @click="fieldModelValue = false"
        >
          Cancel
        </v-btn>

        <slot name="button-slot"></slot>
        <v-btn
          color="green darken-1"
          flat="flat"
          @click="fieldModelValue = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'job',
    'reorder',
    'value',
    'title',
    'showButton',
    'showCancel',
    'maxWidth'
  ],
  computed: {
    fieldModelValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    getOrder () {
      this.reorder()
    }
  }
}
</script>
