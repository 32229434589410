import crypto from 'crypto'

const getAlgorithma=(keyBase64)=>{
    return keyBase64.length==32?'aes-256-cbc':'aes-128-cbc'
}

export function getAlgorithm(keyBase64){
    return getAlgorithma(keyBase64)
}
export function encrypt(payload){
    try{
        const cipher = crypto.createCipheriv(getAlgorithma(process.env.VUE_APP_SA_IV), process.env.VUE_APP_SA_IV, process.env.VUE_APP_SA_KEY)
        let payloadEnc = cipher.update(JSON.stringify(payload), 'utf8', 'base64')
        payloadEnc += cipher.final('base64')
        return payloadEnc
    }catch(e){
        console.log(e)
        return {Error: e.message}
    }
}

export function decrypt(plainText){
    try{
        let result=[]
        let data = plainText.split("\n")
        if(data.length > 1){
            for(let i=0; i<data.length; i++){
                let decipher = crypto.createDecipheriv(getAlgorithm(process.env.VUE_APP_SA_IV), process.env.VUE_APP_SA_IV, process.env.VUE_APP_SA_KEY)
                let decrypted = decipher.update(data[i], 'base64')
                decrypted = Buffer.concat([decrypted, decipher.final()])
                result.push(decrypted.toString())
            }
        }else{
            let decipher = crypto.createDecipheriv(getAlgorithm(process.env.VUE_APP_SA_IV), process.env.VUE_APP_SA_IV, process.env.VUE_APP_SA_KEY)
            let decrypted = decipher.update(data[0], 'base64')
            decrypted = Buffer.concat([decrypted, decipher.final()])
            result.push(decrypted.toString())
        }
        return result.join("\n")
    }catch(e){
        console.log("Error",e)
        return {Error: e}
    }
}