import moment from 'moment'
import { message } from './message'

export const isNumber = (value) => {
    const expect = /^[-+]?[0-9]*\.?[0-9]*$/.test(value);
    return expect
}
export const isDecimal = (value)=>{
    return /^-?(?:0|0\.\d*|[1-9]\d*\.?\d*)$/.test(value)
}
export const unmask = (value, ds = ',')=>{
    return value.toString().replace(ds, '.')
}
export const mask = (value, dp = -1, editing = false, ds = ',', gs = '.')=>{
    if (editing || !isDecimal(value)) return value.replace('.', ds)
    const parts = value.split(/\./)
    let dec = parts[1] || ''
    if(dp >= 0) dec = dec.length < dp ? dec.padEnd(dp, '0') : dec.substr(0, dp)
    if(dec) dec = ds + dec
    return parts[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1' + gs) + dec
}
function toDEC(n){
    if(n==0) n = n.toString()
    if(n!==null && n!==''){
        let pn = n.toString().split('.')
        if(pn.length==1 || (pn.length==2 && /^[-+]?[0-9]+$/.test(pn[0]) && !/^[0-9]+$/.test(pn[1]))) n = parseFloat(n).toFixed('2')
    }
    return n
}
export const numToDEC=(n)=>{
    if(n==0) n = n.toString()
    if(n!==null && n!==''){
        let pn = n.toString().split('.')
        if(pn.length==1 || (pn.length==2 && /^[-+]?[0-9]+$/.test(pn[0]) && !/^[0-9]+$/.test(pn[1]))) n = parseFloat(n).toFixed('1')
    }
    return n
}
export function roundUp(val, dec){
    let result = 5000
    val = val.toString().replace(/[^0-9]/g,'')
    let valOrigin = (parseFloat(val)/5000)
    if(valOrigin>1){
        let valAbs = Math.floor(valOrigin)
        let valDesc = valOrigin - valAbs
        if(valDesc>=dec) result += 5000 * (valAbs+1)
        else result += 5000 * valAbs
    }else{
        if(valOrigin>=dec) result += 5000 * Math.round(valOrigin)
    }
    return result
}
export function roundDown(val, dec){
    let result = 0
    val = val.toString().replace(/[^0-9]/g,'')
    let valOrigin = (parseFloat(val)/5000)
    if(valOrigin>1){
        let valAbs = Math.floor(valOrigin)
        let valDesc = valOrigin - valAbs
        if(valDesc>=dec) result += 5000 * (valAbs+1)
        else result += 5000 * valAbs
    }else{
        if(valOrigin>=dec) result += 5000 * Math.round(valOrigin)
    }
    return result
}
export function numberFormat(inp) {
    if(inp){
        let number_string = inp.toString().replace(/[.]/g,''),
        sisa = number_string.length % 3,
        rupiah = number_string.substr(0, sisa),
        ribuan = number_string.substr(sisa).match(/\d{3}/g);
        if (ribuan) {
            let separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }else{
            rupiah = inp
        }
        return rupiah
    }else{
        return inp
    }
}
export function formatRupiah(angka, tofixed, prefix){
    angka = angka!=null ? angka : '0,0'
    let number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
    split   		= number_string.split(','),
    sisa     		= split[0].length % 3,
    rupiah     		= split[0].substr(0, sisa),
    ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
    if(ribuan){
        let separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    }
    let koma = tofixed&&split[1]==undefined?0 :tofixed&&split[1]!=undefined?split[1].substr(0,tofixed) :split[1]!=undefined?split[1] :'' 
    let awalan = rupiah.substr(0,1)==0&&rupiah.substr(0,2)!='0,'?rupiah.substr(1,rupiah.length-1):rupiah
    // rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    rupiah = koma!=='' ? (awalan?awalan :awalan==''?0 :awalan) + ',' + koma : rupiah
    return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}
export function parseNumber(angka){
    return parseFloat(angka.toString().replace(/[^,\d]/g, '').replace(/,/g,'.'))
}
export function dateFormat(tgl, type) {
    let frt = type == 'date' ? 'YYYY-MM-DD' : type == 'datetime' ? 'YYYY-MM-DD HH:mm' : type == 'time' ? 'HH:mm' : 'YYYY-MM-DD HH:mm.SSS'
    return moment(tgl).format(frt)
}
export function isValidCoordinateFormat(rdata, p) {
    let err = []
    // let nY
    rdata.x1 = toDEC(rdata.x1), rdata.y1 = toDEC(rdata.y1)
    if((rdata.x1==null&&rdata.y1==null)||(rdata.x1==''&&rdata.y1=='')) err.push(message.error[105](`Data coordinate xy1`))
    else if(!/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.x1) || !/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.y1)) err.push(message.error[109](` coordinate xy1`))
    if(!(rdata.x1>=95&&rdata.x1<=141)) err.push(message.error[0](`x1 value DEC must in-between 95.00 up to 141.00`))
    if(!(rdata.y1>=-11&&rdata.y1<=4.4)) err.push(message.error[0](`y1 value DEC must in-between -11.00 up to 4.40`))
    if(rdata.x2!==null&&rdata.x2!=''){
        rdata.x2 = toDEC(rdata.x2), rdata.y2 = toDEC(rdata.y2)
        if(!/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.x2) || !/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.y2)) err.push(message.error[109](` coordinate xy2`))
        if(!(rdata.x2>=95&&rdata.x2<=141)) err.push(message.error[0](`x2 value DEC must in-between 95.00 up to 141.00`))
        if(!(rdata.y2>=-11&&rdata.y2<=4.4)) err.push(message.error[0](`y2 value DEC must in-between -11.00 up to 4.40`))
    }else{
        if(rdata.y2!==null&&rdata.y2!=''){
            err.push(message.error[109](` coordinate xy2`))
        }
    }
    if(rdata.x3!==null&&rdata.x3!=''){
        rdata.x3 = toDEC(rdata.x3), rdata.y3 = toDEC(rdata.y3)
        if((rdata.x2==null||rdata.y2==null)||(rdata.x2==''&&rdata.y2=='')) err.push(message.error[105](`Data coordinate xy2`))
        if(!/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.x3) || !/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.y3)) err.push(message.error[109](` coordinate xy3`))
        if(!(rdata.x3>=95&&rdata.x3<=141)) err.push(message.error[0](`x3 value DEC must in-between 95.00 up to 141.00`))
        if(!(rdata.y3>=-11&&rdata.y3<=4.4)) err.push(message.error[0](`y3 value DEC must in-between -11.00 up to 4.40`))
    }else{
        if(rdata.y3!==null&&rdata.y3!=''){
            if((rdata.x2==null||rdata.y2==null)||(rdata.x2==''&&rdata.y2=='')) err.push(message.error[105](`Data coordinate xy2`))
            err.push(message.error[109](` coordinate xy3`))
        }
    }
    if(rdata.x4!==null&&rdata.x4!=''){
        rdata.x4 = toDEC(rdata.x4), rdata.y4 = toDEC(rdata.y4)
        if((rdata.x2===null||rdata.y2==null)||(rdata.x2===''||rdata.y2=='')) err.push(message.error[105](`Data coordinate xy2`))
        if((rdata.x3==null||rdata.y3==null)||(rdata.x3==''||rdata.y3=='')) err.push(message.error[105](`Data coordinate xy3`))
        if(!/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.x4) || !/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.y4)) err.push(message.error[109](` coordinate xy4`))
        if(!(rdata.x4>=95&&rdata.x4<=141)) err.push(message.error[0](`x4 value DEC must in-between 95.00 up to 141.00`))
        if(!(rdata.y4>=-11&&rdata.y4<=4.4)) err.push(message.error[0](`y4 value DEC must in-between -11.00 up to 4.40`))
    }else{
        if(rdata.y4!==null&&rdata.y4!=''){
            if((rdata.x2===null||rdata.y2==null)||(rdata.x2===''||rdata.y2=='')) err.push(message.error[105](`Data coordinate xy2`))
            if((rdata.x3==null||rdata.y3==null)||(rdata.x3==''||rdata.y3=='')) err.push(message.error[105](`Data coordinate xy3`))
            err.push(message.error[109](` coordinate xy4`))
        }
    }
    if(rdata.x5!==null&&rdata.x5!=''){
        rdata.x5 = toDEC(rdata.x5), rdata.y5 = toDEC(rdata.y5)
        if((rdata.x2===null||rdata.y2==null)||(rdata.x2===''||rdata.y2=='')) err.push(message.error[105](`Data coordinate xy2`))
        if((rdata.x3==null||rdata.y3==null)||(rdata.x3==''||rdata.y3=='')) err.push(message.error[105](`Data coordinate xy3`))
        if((rdata.x4==null||rdata.y4==null)||(rdata.x4==''||rdata.y4=='')) err.push(message.error[105](`Data coordinate xy4`))
        if(!/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.x5) || !/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.y5)) err.push(message.error[109](` coordinate xy5`))
        if(!(rdata.x5>=95&&rdata.x5<=141)) err.push(message.error[0](`x5 value DEC must in-between 95.00 up to 141.00`))
        if(!(rdata.y5>=-11&&rdata.y5<=4.4)) err.push(message.error[0](`y5 value DEC must in-between -11.00 up to 4.40`))
    }else{
        if(rdata.y5!==null&&rdata.y5!=''){
            if((rdata.x2===null||rdata.y2==null)||(rdata.x2===''||rdata.y2=='')) err.push(message.error[105](`Data coordinate xy2`))
            if((rdata.x3==null||rdata.y3==null)||(rdata.x3==''||rdata.y3=='')) err.push(message.error[105](`Data coordinate xy3`))
            if((rdata.x4==null||rdata.y4==null)||(rdata.x4==''||rdata.y4=='')) err.push(message.error[105](`Data coordinate xy4`))
            err.push(message.error[109](` coordinate xy5`))
        }
    }
    if(rdata.x6!==null&&rdata.x6!=''){
        rdata.x6 = toDEC(rdata.x6), rdata.y6 = toDEC(rdata.y6)
        if((rdata.x2===null||rdata.y2==null)||(rdata.x2===''||rdata.y2=='')) err.push(message.error[105](`Data coordinate xy2`))
        if((rdata.x3==null||rdata.y3==null)||(rdata.x3==''||rdata.y3=='')) err.push(message.error[105](`Data coordinate xy3`))
        if((rdata.x4==null||rdata.y4==null)||(rdata.x4==''||rdata.y4=='')) err.push(message.error[105](`Data coordinate xy4`))
        if((rdata.x5==null||rdata.y5==null)||(rdata.x5==''||rdata.y5=='')) err.push(message.error[105](`Data coordinate xy5`))
        if(!/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.x6) || !/^[-+]?[0-9]+\.[0-9]+$/.test(rdata.y6)) err.push(message.error[109](` coordinate xy6`))
        if(!(rdata.x6>=95&&rdata.x6<=141)) err.push(message.error[0](`x6 value DEC must in-between 95.00 up to 141.00`))
        if(!(rdata.y6>=-11&&rdata.y6<=4.4)) err.push(message.error[0](`y6 value DEC must in-between -11.00 up to 4.40`))
    }else{
        if(rdata.y6!==null&&rdata.y6!=''){
            if((rdata.x2===null||rdata.y2==null)||(rdata.x2===''||rdata.y2=='')) err.push(message.error[105](`Data coordinate xy2`))
            if((rdata.x3==null||rdata.y3==null)||(rdata.x3==''||rdata.y3=='')) err.push(message.error[105](`Data coordinate xy3`))
            if((rdata.x4==null||rdata.y4==null)||(rdata.x4==''||rdata.y4=='')) err.push(message.error[105](`Data coordinate xy4`))
            if((rdata.x5==null||rdata.y5==null)||(rdata.x5==''||rdata.y5=='')) err.push(message.error[105](`Data coordinate xy5`))
            err.push(message.error[109](` coordinate xy6`))
        }
    }
    return err
}


export function isValidLength(rdata, field, lt, sc) {
    let cond1 = rdata[field].toString().length >= lt.min
    let cond2 = !!lt.max ? rdata[field].toString().length <= lt.max : true
    if ((cond1 && cond2) &&
        (!sc || (!!sc && (sc.reg1.test(rdata[field].toString().substr(sc.from1, sc.length1))) &&
            (!sc.reg2 || (sc.reg2&&sc.reg2.test(rdata[field].toString().substr(sc.from2, sc.length2))))))) {
        return { status: true }
    } else if (!cond1 || !cond2) {
        return { status: false, message: `Min ${lt.min} karakter dan Max ${lt.max}` }
    } else if (!!sc) {
        return { status: false, message: `invalid regex` }
    }
}
// TODO return status (boolean) & message
export function isValidComposition(rdata, opr, r, field) {
    let err = ''
    if (opr == 'eq') {
        if (rdata[field]&&rdata[field].toString().length != r) err = message.error[110](field, `Harus ${r} Digit`)
    } else if (opr == 'gt') {
        if (rdata[field]&&rdata[field].toString().length <= r) err = message.error[110](field, `Harus lebih dari ${r} Digit`)
    } else if (opr == 'gte') {
        if (rdata[field]&&rdata[field].toString().length < r) err = message.error[110](field, `Harus lebih dari atau sama dengan ${r} Digit`)
    } else if (opr == 'lt') {
        if (rdata[field]&&rdata[field].toString().length >= r) err = message.error[110](field, `Harus kurang dari ${r} Digit`)
    } else if (opr == 'lte') {
        if (rdata[field]&&rdata[field].toString().length > r) err = message.error[110](field, `Harus lebih dari atau sama dengan ${r} Digit`)
    }
    if (err === '')
        return { status: true }
    else
        return { status: false, message: err }
}
Array.prototype.unique = function() {
    var a = this.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a
}
export function newFormatNumber(value) {
    // Remove any existing dots and non-numeric characters
    const cleanedValue = value.replace(/[^\d]/g, '');
    
    // Insert dots after every three digits from the right
    let formattedValue = '';
    for (let i = cleanedValue.length - 1; i >= 0; i--) {
      formattedValue = cleanedValue[i] + formattedValue;
      if ((cleanedValue.length - i) % 3 === 0 && i !== 0) {
        formattedValue = '.' + formattedValue;
      }
    }
    return formattedValue;
  }