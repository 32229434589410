<template>
   <img src="@/assets/img/full_logo.png"/>
</template>

<script>
export default {
  name: 'dotms_comp_title',
  props: {
    title: {
      type: String,
      default: 'Header Title'
    },
    color: {
      type: String,
      default: 'white'
    },
    fontType: {
      type: String,
      default: 'Poppins, sans-serif'
    },
    fontSize: {
      type: Number,
      default: 14
    }
  }
}
</script>