<template>
  <div>
    <v-text-field id="entryFile" :readonly="true" v-model="filename" :outlined="outlined" :hide-details="hideDetails" dense :label="label" :required="required" @click.native="onFocus" :disabled="disabled" ref="fileTextField" append-icon="mdi-folder-search"/>
    <input id="fileInput" type="file" :accept="accept" :multiple="multiple" :disabled="disabled" ref="fileInput" onclick="this.value=null;" @change="onFileChange" style="cursor:pointer!important;"/>
  </div>
</template>

<script>
  export default {
    name: 'dotms_comp_entryfile',
    props: {
      value: {
        type: [Array, String]
      },
      accept: {
        type: String,
        default: '*'
      },
      label: {
        type: String,
        default: 'choose_file'
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      outlined: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        filename: ''
      }
    },
    watch: {
      value(v) {
        this.filename = v
      }
    },
    mounted() {
      this.filename = this.value
    },
    methods: {
      getFormData(files) {
        const forms = []
        for (const file of files) {
          const form = new FormData()
          form.append('data', file, file.name)
          forms.push(form)
        }
        return forms
      },
      onFocus() {
        if (!this.disabled) {
          this.$refs.fileInput.click()
        }
      },
      async onFileChange($event) {
        const files = $event.target.files || $event.dataTransfer.files
        const form = this.getFormData(files)
        if (files) {
          if(files.length > 0){
            this.filename = [...files].map(file => file.name).join(', ')
          }else{
            this.filename = null
          }
        } else {
          this.filename = $event.target.value.split('\\').pop()
        }
        this.$emit('fullPath', this.$refs.fileInput.value)
        this.$emit('input', this.filename)
        this.$emit('selectedFile', files[0])
      },
    }
  }
</script>

<style>
  input[type=file] {
    position: absolute;
    left: -99999px;
    cursor: pointer !important;
  }
  #entryFile{
    cursor: pointer !important;
  }
  i.v-icon.notranslate.mdi.mdi-folder-search.theme--light{
    cursor: pointer !important;
  }
</style>