<template>
  <div :style="style_">
    <v-badge
      bordered
      :color="badgeColor ? badgeColor : bgColor"
      :content="badgeValue"
      :value="badgeValue"
      overlap
    >
      <v-icon v-if="badgeIcon" slot="badge" :color="badgeIconColor">
        {{ badgeIcon }}
      </v-icon>
      <v-btn
        small
        block
        class="white--text"
        :color="btnColor"
        depressed
        overlap
      >
        {{ btnText }}
      </v-btn>
    </v-badge>
  </div>

  <!-- <core-child-dashboard
            :style_="addInfo[5].style"
            :badgeIcon="addInfo[5].badgeIcon"
            :badgeIconColor="addInfo[5].badgeIconColor"
            :badgeColor="addInfo[5].badgeColor"
            :badgeValue="addInfo[5].value"
            :btnText="addInfo[5].text"
            :btnColor="addInfo[5].bgColor"
            v-if="addInfo.length > 5 && !addInfo[5].isEmpty"
          ></core-child-dashboard> -->
</template>

<script>
export default {
  name: "dotms_comp_childdashboard",
  props: {
    style_: {
      type: String,
      default: "",
    },
    badgeValue: {
      type: String,
      default: ``,
    },
    bgColor: {
      type: String,
      default: "#e5e5e5",
    },
    badgeIcon: {
      type: String,
      default: "",
    },
    badgeIconColor: {
      type: String,
      default: "white",
    },
    badgeColor: {
      type: String,
      default: "white",
    },
    btnText: {
      type: String,
      default: "",
    },
    btnColor: {
      type: String,
      default: "#e5e5e5",
    },
  },
};
</script>