<template>
  <h1 class="title"
    :style="`${styles}color:${color}!important;font-size:${fontSize}px!important; font-family:${fontType} !important`"
    v-on:click="() => action(true)">
    {{ title }}
    <span v-if="subtitle" :style="subtitleStyle">{{ subtitle }}</span>
  </h1>
</template>

<script>
export default {
  name: "dotms_comp_title",
  props: {
    styles: {
      type: String,
      default: "",
    },
    subtitleStyle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Header Title",
    },
    subtitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "white",
    },
    fontType: {
      type: String,
      default: "Poppins, sans-serif",
    },
    fontSize: {
      type: Number,
      default: 14,
    },
  },
  methods: {
    action() {
      this.$emit('action');
    },
  }
};
</script>