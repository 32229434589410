<template>
  <v-flex xs12>
    <div class="primary py-2 px-3">
      <h1 class="font-white title">{{ title }}</h1>
    </div>
  </v-flex>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style>
.header-title {
  border-radius: 5px;
}
.font-white {
  color: #FFFFFF;
}
</style>
