<template>
  <v-card v-show="show" class="pa-2 ma-2 absolute">
    <v-list>
      <v-list-item v-for="(item, i) in itemList" :key="i">
        <v-list-item-icon>
          <v-icon :style="item.iconStyle ? item.iconStyle : ''">
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="mb-0" v-bind="attrs" v-on="on">
                <span class="text-h5">{{ item.value }}</span>
                {{ item.text }}

                <span
                  :style="item.changesIconStyle ? item.changesIconStyle : ''"
                >
                  <v-icon
                    :style="item.changesIconStyle ? item.changesIconStyle : ''"
                  >
                    {{ item.changesIcon }}
                  </v-icon>
                  {{ item.changesValue }}
                </span>
              </p>
            </template>
            <span>{{ item.conclusion }}</span>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "expanded_panel_list_dashboard",
  props: {
    show: Boolean,
    itemList: Array,
  },
  methods: {},
};
</script>
