import {
    LOGIN,LOGIN_SUCCESS,LOGIN_FAILED,LOGOUT,
    STORE_SESSION
} from './mutation-types'

export default {
    [LOGIN](state) {
        state.session = null
    },
    [LOGIN_SUCCESS](state, data) {
        state.session = data
        if(['ADMIN','Admin Data','Korlap'].includes(data.sRole)){
            let dataY = JSON.stringify(data)
            let tempData = btoa(dataY)
            localStorage.setItem('_1100_session', tempData)
        }
    },
    [LOGIN_FAILED](state) {
        state.session = state.session
    },
    [LOGOUT](state) {
        state.session = null
        document.cookie = 'connect.sid=; Max-Age=-99999999; path=/;';
        document.cookie = 'valid=; Max-Age=-99999999; path=/;';
        localStorage.clear()
    },
    [STORE_SESSION](state, session) {
        state.session = session
        localStorage.setItem('_1100_session', btoa(JSON.stringify(data)))
    },
}