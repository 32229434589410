<!-- <template>
  <v-card class="mx-auto flex card-widget" :style="`height:${height}!important;`" shaped>
    <v-card class="mx-auto flex" :disabled="disabled" shaped :style="`cursor:pointer;display:flex;background:${bgColor};${styles}`" @click="action">
      <div class="widget-content">
        <div class="widget-icon" :class="titleRight ? 'mb-4' : ''">
          <v-row v-if="img!='' || icon!=''">
            <v-flex xs12 md3>
             <v-avatar v-if="img!=''">
               <v-img :src="img"/>
             </v-avatar>
             <v-avatar size="50" tile color="transparent" v-else><v-icon :color="bgColor">{{icon}}</v-icon></v-avatar>
            </v-flex>
            <v-flex xs12 md9>
              <v-card-title v-if="titleRight" class="title-widget" :style="`color:${textColor}!important;${textStyles}; justify-content: center;`">
                {{titleRight}}
              </v-card-title>
              <v-avatar v-if="imgRight!=''">
                <v-img :src="imgRight"/>
              </v-avatar>
            </v-flex>
          </v-row>
          <v-row v-else>
            <v-flex xs12 md10>
             <v-card-title v-if="titleLeft" class="title-widget" :style="`color:${textColor}!important;${textStyles}; justify-content: left;`">
                {{titleLeft}}
              </v-card-title>
              <v-card-subtitle>
                <span class="unit">{{headcount}}</span>
              </v-card-subtitle>
            </v-flex>
            <v-flex xs12 md2 class="right-position-icon">
              <v-avatar v-if="imgRight!=''">
                <v-img :src="imgRight"/>
              </v-avatar>
            </v-flex>
          </v-row>
        </div>
        <v-card-title v-if="title" :style="`color:${textColor}!important;${textStyles}`">
          {{title ? title : ''}}
        </v-card-title>
        <v-card-subtitle v-if="title">
          <span class="unit">{{headcount}}</span>
          <span class="sub-unit" v-if="subheadcount!=''">{{subheadcount}}</span>
        </v-card-subtitle>
      </div>
    </v-card>
    <div class="widget-action" v-if="addInfo.length>0">
      <div class="widget-action-item" @click="action1">
        <span class="dot-circle" v-if="addInfo[0].enableColorSign" :style="`background:${addInfo[0].color?addInfo[0].color:bgColor} !important`"></span>
        <span v-if="addInfo[0].text" :color="addInfo[0].color?addInfo[0].color:bgColor">{{ addInfo[0].text }}</span>
        <span v-if="addInfo[0].value" :color="addInfo[0].color?addInfo[0].color:bgColor">{{ addInfo[0].value }}</span>
      </div>
      <div class="widget-action-item" @click="action2" v-if="addInfo.length>1">
        <span class="dot-circle" v-if="addInfo[1].enableColorSign" :style="`background:${addInfo[1].color?addInfo[1].color:bgColor} !important`"></span>
        <span v-if="addInfo[1].text" :color="addInfo[1].color?addInfo[1].color:bgColor">{{ addInfo[1].text }}</span>
        <span v-if="addInfo[1].value" :color="addInfo[1].color?addInfo[1].color:bgColor">{{ addInfo[1].value }}</span>
      </div>
    </div>
  </v-card>
  </template> -->
  
  <template>
    <v-card class="mx-auto flex card-widget" shaped>
      <v-card class="mx-auto flex" :disabled="disabled" shaped :style="`cursor:pointer;display:flex;background:${bgColor};${styles}`" @click="action">
        <div class="widget-content">
          <div class="widget-icon" :class="titleRight ? 'mb-4' : ''">
            <v-row>
              <v-flex xs12 md3>
               <v-avatar v-if="img!=''">
                 <v-img :src="img"/>
               </v-avatar>
               <v-avatar size="50" tile color="transparent" v-else><v-icon :color="bgColor">{{icon}}</v-icon></v-avatar>
              </v-flex>
              <v-flex xs12 md9 v-if="titleRight">
                <v-card-title :style="`color:${textColor}!important;${textStyles}; justify-content: center;`">
                  {{titleRight}}
                </v-card-title>
              </v-flex>
              <v-flex xs12 md9 v-if="headcountRight">
                <v-card-title :style="`justify-content: right; font-size: 40px !important; color:#37373F !important`">
                  {{headcountRight}}
                </v-card-title>
              </v-flex>
            </v-row>
          </div>
          <v-card-title :style="`color:${textColor}!important;${textStyles}`">
            {{title ? title : ''}}
          </v-card-title>
          <v-card-subtitle v-if="headcount || subheadcount">
            <span class="unit">{{headcount}}</span>
            <span class="sub-unit" v-if="subheadcount!=''">{{subheadcount}}</span>
          </v-card-subtitle>
        </div>
      </v-card>
      <div class="widget-action" v-if="addInfo.length>0">
        <div class="widget-action-item" @click="action1">
          <span class="dot-circle" v-if="addInfo[0].enableColorSign" :style="`background:${addInfo[0].color?addInfo[0].color:bgColor} !important`"></span>
          <span v-if="addInfo[0].text" :style="`color: ${addInfo[0].color}`" :color="addInfo[0].color?addInfo[0].color:bgColor">{{ addInfo[0].text }}</span>
          <span v-if="addInfo[0].value" :color="addInfo[0].color?addInfo[0].color:bgColor">{{ addInfo[0].value }}</span>
        </div>
        <div class="widget-action-item" @click="action2" v-if="addInfo.length>1">
          <span class="dot-circle" v-if="addInfo[1].enableColorSign" :style="`background:${addInfo[1].color?addInfo[1].color:bgColor} !important`"></span>
          <span v-if="addInfo[1].text" :style="`color: ${addInfo[1].color}`" :color="addInfo[1].color?addInfo[1].color:bgColor">{{ addInfo[1].text }}</span>
          <span v-if="addInfo[1].value" :color="addInfo[1].color?addInfo[1].color:bgColor">{{ addInfo[1].value }}</span>
        </div>
      </div>
    </v-card>
  </template>
    <script>
    export default {
      name: 'dotms_comp_widget4',
      props: {
        bordered: {
          type: Boolean,
          default: true
        },
        disabled: {
          type: Boolean,
          default: false
        },
        addInfo: {
          type: Array,
          default:()=> []
        },
        styles: {
          type: String,
          default: ''
        },
        bgColor: {
          type: String,
          default: 'white'
        },
        textColor: {
          type: String,
          default: '#000'
        },
        height: {
          type: String,
          default: 'auto'
        },
        textStyles: {
          type: String,
          default: ''
        },
        headcount: {
          type: String,
          default: ''
        },
        subheadcount: {
          type: String,
          default: ''
        },
        headcountRight: {
          type: String,
          default: ''
        },
        title: {
          type: String,
          default: ''
        },
        titleRight: {
          type: String,
          default: ''
        },
        titleLeft: {
          type: String,
          default: ''
        },
        img: {
          type: String,
          default: ''
        },
        imgRight: {
          type: String,
          default: ''
        },
        icon: {
          type: String,
          default: ''
        }
      },
      methods:{
        action(){
          this.$emit('action');
        },
        action1(){
          this.$emit('action1');
        },
        action2(){
          this.$emit('action2');
        },
        action3(){
          this.$emit('action3');
        },
        action4(){
          this.$emit('action4');
        },
        subaction(){
          this.$emit('subaction');
        }
      }
    }
    </script>
    <style scoped>
    .addInfo{
      position: absolute;
      right: 20px;
      top: 60px;
    }
    .card-widget {
      display:flex;
      background:#E5E5E5;
      margin:6px;
      border-radius:6px;
    }
    .right-position-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    </style>