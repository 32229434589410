<template>
  <v-row>
    <v-col md="6" class="">
      <v-select
        v-model="selectedFilter_"
        :items="listFilter_"
        @change="onChangeFilter"
        item-value="value"
        item-text="text"
        menu-props="auto"
        hide-details
        :label="label"
        :single-line="false"
      ></v-select>
    </v-col>

    <v-col md="6">
      <v-select
        :disabled="!showFilter2_"
        v-model="selectedFilter2_"
        :items="listFilter2_"
        @change="onChangeFilter2"
        item-value="value"
        item-text="text"
        menu-props="auto"
        hide-details
        :label="label2"
        :single-line="false"
      ></v-select>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "dotms_comp_filter_select",

  props: {
    selectedFilter: {
      type: String,
      default: "*",
    },

    selectedFilter2: {
      type: String,
      default: "*",
    },

    listFilter: {
      type: [],
      default: () => [{ value: "*", text: "" }],
    },

    listFilter2: {
      type: [],
      default: () => [{ value: "*", text: "" }],
    },

    label: {
      type: String,
      default: "Filter By",
    },

    label2: {
      type: String,
      default: "Filter By",
    },

    showFilter2: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedFilter_: this.selectedFilter ? this.selectedFilter : "",
      selectedFilter2_: this.selectedFilter2 ? this.selectedFilter2 : "",

      listFilter_: this.listFilter ? this.listFilter : [],
      listFilter2_: this.listFilter2 ? this.listFilter2 : [],

      showFilter2_: this.showFilter2 ? this.showFilter2 : false,
    };
  },

  methods: {
    onChangeFilter() {
      this.$emit("onChangeFilter", this.selectedFilter_);
    },

    onChangeFilter2() {
      this.$emit("onChangeFilter2", this.selectedFilter2_);
    },
  },

  watch: {
    selectedFilter(val) {
      this.selectedFilter_ = val;
    },

    selectedFilter2(val) {
      this.selectedFilter2_ = val;
    },

    listFilter2(val) {
      this.listFilter2_ = val;
    },

    showFilter2(val) {
      this.showFilter2_ = val;
    },
  },
};
</script>
