<template>
  <v-app id="keep" class="white">
    <core-navigationDrawer/>
    <core-viewPage/>
  </v-app>
</template>

<script>
import '@/assets/css/index.css'
import '@/assets/css/header.css'
import '@/assets/css/body.css'
import '@/assets/css/form.css'
import '@/assets/css/table.css'
import '@/assets/css/footer.css'
export default { name: 'App' }
</script>