<template>
    <v-col v-bind:class="cssClass">
        <v-card v-bind:class="marginClass ? marginClass : 'mx-1 my-1'" :disabled="disabled" shaped :style="`cursor:pointer;background:${bgColor};${styles}`" @click="mainAction">
            <div class="widget-content" >
                <v-card-title :style="`color:${textColor}!important;${textStyles}`">
                    <div class="d-flex flex-row" style="align-items: center;">
                        <v-avatar v-if="img != ''">
                            <v-img :src="img" />
                        </v-avatar>
                        <v-avatar size="30" tile color="transparent" v-else><v-icon :color="bgColor">{{ icon }}</v-icon></v-avatar>
                        <span class="ml-3" style="font-size: 13px; ">{{ title ? title : '' }}</span>
                    </div>
                </v-card-title>
                <div>
                    <div v-if="addInfo.length > 0">
                        <div class="widget-action" v-if="addInfo.length>0">
                            <div class="widget-action-item resize" @click="action1">
                                <span v-if="addInfo[0].text" :style="`color: ${addInfo[0].textColor}`" :color="addInfo[0].textColor">{{ addInfo[0].text }}</span>
                                <span v-if="addInfo[0].value" :color="addInfo[0].textColor">{{ addInfo[0].value }}</span>
                            </div>
                            <div class="widget-action-item resize" @click="action2">
                                <span v-if="addInfo[1].text" :style="`color: ${addInfo[1].textColor}`" :color="addInfo[1].textColor">{{ addInfo[1].text }}</span>
                                <span v-if="addInfo[1].value" :color="addInfo[1].textColor">{{ addInfo[1].value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </v-col>
</template>
<script>
export default {
    name: 'dotms_comp_widget5',
    props: {
        cssClass: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        addInfo: {
            type: Array,
            default: () => []
        },
        styles: {
            type: String,
            default: ''
        },
        bgColor: {
            type: String,
            default: 'white'
        },
        textColor: {
            type: String,
            default: '#000'
        },
        textStyles: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        img: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        marginClass: {
            type: String,
            default: ''
        }
    },
    methods: {
        mainAction() {
            this.$emit('mainAction')
        },
        action1() {
            this.$emit('action1');
        },
        action2() {
            this.$emit('action2');
        }
    }
}
</script>
<style scoped>
.addInfo {
    position: absolute;
    right: 20px;
    top: 60px;
}

.card-widget {
    display: flex;
    background: #E5E5E5;
    border-radius: 6px;
}
.widget-action {
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
}
.right-position-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.widget-action-item.resize > * + * {
      font-size: 24px !important;
      font-weight: 700 !important;
  }
  .unit.resize {
    font-size: 24px !important
  }
</style>