

<template>
  <v-text-field v-if="type==='date'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined" />
  <v-text-field v-else-if="type==='time'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined"/>
  <v-text-field v-else-if="type==='datetime'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined"/>
  <v-menu v-else-if="type==='datepicker' || type==='daterange'" ref="displaymenu"
    v-model="displaymenu" :return-value.sync="displaymenu"
    :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :dense="dense" v-model="displayedValue" 
        :label="displayedLabel" :rules="fieldRules" readonly v-bind="attrs" v-on="on" append-icon="mdi-calendar" :outlined="outlined">
      </v-text-field>
    </template>
    <v-date-picker v-model="entrymodel" @input="$emit('input', entrymodel)"
      no-title :range="type=='daterange'" :min="min" :max="max" scrollable>
      <v-spacer v-if="entrymodel!=null"></v-spacer>
      <v-btn text color="primary" v-if="entrymodel!=null && isRequired==false" @click="entrymodel=null;$emit('input', entrymodel);displaymenu=false;"> Clear</v-btn>
      <v-btn text color="primary" v-if="entrymodel!=null" @click="$refs.displaymenu.save(entrymodel)"> Cancel</v-btn>
    </v-date-picker>
  </v-menu>
  <v-menu v-else-if="type === 'monthYear'" ref="displaymenu" v-model='displaymenu' :close-on-content-click="false" :nudge-right='40' transition='scale-transition' offset-y
      min-width='290px'>
      <template v-slot:activator='{ on, attrs }'>
          <v-text-field :dense="dense" v-model='displayedValue' :label="displayedLabel" :outlined="outlined" append-icon='mdi-calendar' readonly v-bind='attrs'
              v-on='on'></v-text-field>
      </template>
      <v-date-picker v-model='entrymodel' @input="$emit('input', entrymodel)" :min="min" :max="max" color='primary' scrollable type="month"
          ref="picker">
        <v-spacer></v-spacer>
        <v-btn text color="primary" v-if="entrymodel!=null && isRequired==false" @click="entrymodel=null; $emit('input', entrymodel);displaymenu=false;"> Cancel</v-btn>
        <v-btn text color="primary" v-if="entrymodel!=null" @click="$refs.displaymenu.save(entrymodel)"> Ok</v-btn>
      </v-date-picker>
  </v-menu>

  <v-text-field v-else-if="type==='rangesdate'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined"/>
  <v-text-field v-else-if="type==='decimal'" v-model="entrymodel2" @input="$emit('input', entrymodel2)" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" @blur="focus.entrymodel=false" @focus="focus.entrymodel=true" placeholder="Placeholder" :outlined="outlined"></v-text-field>
  <v-text-field v-else-if="type==='number'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined"/>
  <v-text-field v-else-if="type==='memo'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined"/>
  <v-text-field v-else-if="type==='checkbox'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined"/>
  
  <!-- <v-text-field v-else-if="type==='radio'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined"/> -->

<v-container fluid v-else-if="type==='radio'">
  <div>{{displayedLabel}}</div>
  <v-radio-group style="margin:2px" row v-model="entrymodel" :disabled="disabled" :required="required" ref="fileTextField" :outlined="outlined" @change="onChange" :id="id">
    <v-radio v-for="(data,i) in dataItem" :key="i" :label="data.text" :value="data.value"></v-radio>
  </v-radio-group>
</v-container>

  <v-text-field v-else-if="type==='switch'" v-model="entrymodel" :label="displayedLabel" :dense="dense" :required="required" @click="onClick" :disabled="disabled" ref="fileTextField" :outlined="outlined"/>
  
  <v-text-field v-else
    :filled="filled"
    :title="title" 
    :color="color" 
    :class="pclass" 
    v-model="entrymodel" 
    :type="type" 
    :hide-details="hidedetails"
    :prepend-inner-icon="leftIcon" 
    :clearable="clearable"
    @click:append="onClickAppend" 
    @change="onChange"
    @keyup="onKeyUp"
    @keypress="onKeypress"
    :background-color="bgcolor" 
    :maxlength="max" 
    @input="onInput" 
    :rules="fieldRules" 
    :label="displayedLabel" 
    :required="required" 
    :dense="dense" 
    :disabled="disabled" 
    :outlined="outlined"
    :readonly="readonly"
    ref="test"
    :placeholder="placeholder">
    <template v-slot:append v-if="rightIcon!=''">
      <v-fade-transition leave-absolute>
        <v-flex style="display: flex; align-items: flex-start" v-if="rightIcon.substr(0,3).toUpperCase()!='URL'" :color="iconColor" :style="`color:${iconColor}!important;cursor:pointer;font-size:12px!important; margin-top: 5px;`">
          <v-icon @click="onClickAppend" :color="iconColor" :style="`color:${iconColor}!important`">{{rightIcon}}</v-icon><span>{{iconTitle}}</span> </v-flex>
        <v-flex v-else><img @click="onClickAppend" width="24" height="24" :src="rightIcon.substr(3,rightIcon.length)" alt="">{{iconTitle}}</v-flex>
      </v-fade-transition>
    </template>
  </v-text-field>

</template>

<script>
import {mask, unmask} from '@/consistencychecker/formvalidator/format'
export default {
  name: 'dotms_comp_entry',
  props: {
    filled:{
      type: Boolean,
      default: false
    },
    title:{
      type: String,
      default: ''
    },
    placeholder:{
      type: String,
      default: ''
    },
    id:{
      type: String,
      default: ''
    },
    parentid:{
      type: [String, Number]
    },
    value: {
      type: [Array, String, Number]
    },
    action: {
      type: Function,
      default: () => this.onClickAppend()
    },
    actionChange: {
      type: Function,
      default: () => this.onChange()
    },
    actionKeyup: {
      type: Function,
      default: () => this.onKeyUp()
    },
    hidedetails:{
      type: String,
      default: ''
    },
    color:{
      type: String,
      default: ''
    },
    iconColor:{
      type: String,
      default: 'white'
    },
    bgcolor: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    leftIcon: {
      type: String,
      default: ''
    },
    rightIcon: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconTitle: {
      type: String,
      default: ''
    },
    clearable:{
      type: Boolean,
      default: false
    },
    isRequired:{
      type: Boolean,
      default: false,
    },
    pclass:{
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    accept: {
      type: String,
      default: '*'
    },
    label: {
      type: String,
      default: 'choose_file'
    },
    required: {
      type: Boolean,
      default: false
    },
    enableMarkForRequiredField: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, String],
      default: null
    },
    max: {
      type: [Number, String],
      default: null
    },
    max: {
      type: [Number, String],
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    onClick: {
      type: Function,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    dataItem: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      entrymodel: null,
      displaymenu: false,
      displayedLabel: this.label ? this.label : "",
      fieldRules: [],
      focus: {
        entrymodel: false
      },
      entrymodel_: '0'
    }
  },
  watch: {
    value(v) {
      this.entrymodel = v
      this.entrymodel2 = v
    },
    label(val) {
      this.displayedLabel = val;
    },
  },
  computed: {
    displayedValue () {
      return Array.isArray(this.entrymodel) ? this.entrymodel.join(' ~ ') : this.entrymodel
    },
    entrymodel2: {
      get(){
         if(this.type=='decimal') return mask(this.entrymodel_, 2, this.focus.entrymodel)
      },
      set(value) {
        if(this.type=='decimal') this.entrymodel_ = unmask(value)
      }
    }
  },
  mounted() {
    this.entrymodel = this.value
    if(this.required && this.enableMarkForRequiredField)
      this.displayedLabel = this.label+"*"
    else 
      this.displayedLabel = this.label
    // if(this.required)
    //   this.fieldRules.push(v => !!v || this.label+' is required')
    this._.forEach(this.rules, (val, key) => {
        this.fieldRules.push(val)
    });
  },
  methods: {
      onClickAppend() {
        this.$emit('action');
      },
      onChange() {
        this.$emit('actionChange', this.id, this.entrymodel, this.parentid);
      },
      onKeyUp() {
        this.$emit('actionKeyup', this.id, this.entrymodel, this.parentid);
      },
      onKeypress() {
        this.$emit('keypress')
      },
      onInput() {
        this.$emit('input', this.entrymodel, this.$event)
      }
  }
}
</script>

<style scoped>
input[type=file] {
  position: absolute;
  left: -99999px;
}
</style>