import axios from 'axios'
import https from 'https'
import { getCookie } from '@/plugins/cookie'
import { getTimeZone } from '@/plugins/gmt'
import { getHostNameAttr } from '@/plugins/hostname'

export function buildQuery(params = {}) {
  const queries = params || {}
  const esc = encodeURIComponent
  const obj = Object.assign({}, queries)
  return '?' + Object.keys(obj).map(k => esc(k) + '=' + esc(obj[k])).join('&')
}
export const http = () => {
  return axios.create({
    baseURL: (getCookie('endpointGW') ? getCookie('endpointGW') : process.env.VUE_APP_END_GW) + process.env.VUE_APP_ALIAS_GW,
    headers: {
      'X-Service-ID': '1100.WEB',
      'X-Service-Key': btoa(process.env.VUE_APP_SA_KEY + ',' + process.env.VUE_APP_SA_IV + ',i69OVb8quzB/dLRiPjFupg=='),
      'X-GMT-Device': getTimeZone(),
      'X-HOSTNAME-ATTR': getHostNameAttr(localStorage.getItem('_1100_username')),
      'Content-Type': 'application/json; charset=utf-8',
    }, maxRedirects: 0, httpsAgent: new https.Agent({ rejectUnauthorized: false })
  })
}