<template>
  <v-layout row wrap>
    <!-- FADE CSS ANIMATION -->
    <v-fade-transition>
      <!-- DIALOG -->
      <v-flex xs12>
        <material-table-card v-if="fShowTbl" :title="lTitle" :with-attribute="fWithAtt" :with-button="fWithBtn">
          <!-- FILTER -->
          <template slot="search" v-if="fSearch">
            <v-text-field v-model="vSearch" append-icon="search" label="Search" solo hide-details />
          </template>
          <!-- TABLE -->
          <template slot="table">
            <!-- PROPS HEADER -->
            <v-data-table :pagination.sync="vPaging" :headers="tHeader" :items="tData" :loading="fLoader">
              <!-- PROGRESS BAR -->
              <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
              <!-- PROPS DATA -->
              <template slot="items" slot-scope="props">
                <td class="text-xs-left" v-for="(vdata, i) in props.item" :key="i">{{ vdata }}</td>
              </template>
              <!-- IF DATA NOT FOUND -->
              <v-alert slot="no-results" :value="true" color="primary" icon="warning">
                Your search for "{{ vSearch }}" found no results.
              </v-alert>
            </v-data-table>
          </template>
        </material-table-card>
      </v-flex>
    </v-fade-transition>

    <!-- ALERT & DIALOG -->
    <div class="text-center">
      <Alert :flagShow="fShowAlert" :txtType="lTypeAlert" :txtMsgAlert="lMsgAlert" />
      <Dialog :flagShow="fShowDialog"/>
    </div>

  </v-layout>
</template>

<script>
// IMPORT LIBRARY (FUNCTION & COMPONENT)
import moment from 'moment'
import Alert from './Alert'
import Dialog from './Dialog'

export default {
  name: 'Table',
  components: { Alert, Dialog },
  props: ['flagShow','txtTitle','flagWithAtt','flagWithBtn','flagSearch','valSearch', 'valPaging','tblHeader','tblData','flagLoader'],
  data () { // DEFAULT VALUE
    return {
      fShowAlert: false, lTypeAlert:'Success', lMsgAlert:'Sukses', fShowDialog:false,
      fShowTbl: this.flagShow ? this.flagShow : true, 
      lTitle: this.txtTitle ? this.txtTitle : 'Listing Data',
      fWithAtt: this.flagWithAtt ? this.flagWithAtt : true,
      fWithBtn: this.flagWithBtn ? this.flagWithBtn : true,
      fSearch: this.flagSearch ? this.flagSearch : true,
      vSearch: this.valSearch ? this.valSearch : '',
      vPaging: this.valPaging ? this.valPaging : {rowsPerPage:-1},
      tHeader: this.tblHeader ? this.tblHeader : [{text: 'Column 1', value: 'filed1'},{text: 'Column 2', value: 'field2'},{text: 'Column 3', value: 'field3'}],
      tData: this.tblData ? this.tblData : [],
      fLoader: this.flagLoader ? this.flagLoader : true,
    }
  },
  beforeMount(){ // CALL DATA
    if(this.$store.getters['auth/getEstate']&&this.$store.getters['auth/getEstate'].length){
      this.estateList = this.$store.getters['auth/getEstate'].map((element) => {
        return { text: element.estateID, value: element.estateID }
      })
      this.estateID = this.$store.getters['auth/getEstate'][0].estateID
    }
  },
  mounted() { // IF NEED RE-RENDER UI OR FUNCTION REPLACE DEFAULT VALUE
    this.fLoader = false
  },
  methods: { // CALL METHOD (AS NEDDED ON THIS PAGE)
    reqTimeout(){
      window.localStorage.clear(); 
      document.cookie='connect.sid=;Max-Age=-99999999;path=/;'; 
      document.cookie='key.sid=;Max-Age=-99999999;path=/;'; 
      window.location.href=window.location.origin+'/'+window.location.pathname.split('/')[1]
    },
    submitFilter () {

    },
  },
  watch: { // READ EVENT AT VARIABLE (BASED ON VARIABLE TEMPLATE)
    sortMethod(val) {
      
    },
  },
  filters: { // CHANGE FORMAT TEXT/VARIABLE
    dateFormat (value) {
      if (value) return value.substr(0, 10)
    },
    isAvailable (value) {
      if(!value) return ''
      if(value===9999) return 'N/A'
    }
  },
}
</script>

<style scoped>
td.td-zero-padding {
  padding: 0;
}
tr.tr-full-width {
  width: 100%;
  padding: 0 24px;
  display: block;
  border-bottom: 0 !important;
}
</style>