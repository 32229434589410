export default [
    // LOGIN
    {
        path: '/login',
        view: 'Login/Main',
        name: 'Login',
        meta: { breadcrumb: [{ text: 'Login', disabled: true, href: '/login' }] }
    },
    // HOMESCREEN
    {
        path: '/home',
        name: 'Home',
        view: 'HomeScreen/Main',
        meta: { requiresAuth:true, breadcrumb: [{ text: 'Home', disabled: true, href: '/home' }] }
    },
    {
        path: '/users',
        name: 'Users',
        view: 'Users/Main',
        meta: { requiresAuth:true, breadcrumb: [{ text: 'Home', disabled: true, href: '/home' }] }
    },
    {
        path: '/voters',
        name: 'Voters',
        view: 'Voters/Main',
        meta: { requiresAuth:true, breadcrumb: [{ text: 'Home', disabled: true, href: '/home' }] }
    },
    {
        path: '/voting',
        name: 'Voting',
        view: 'Voting/Main',
        meta: { requiresAuth:true, breadcrumb: [{ text: 'Home', disabled: true, href: '/home' }] }
    },
]