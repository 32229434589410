<template>
  <v-btn style="width:100%" v-if="btndefault === true"  v-show="shown" :icon="type == 'icon'" :fab="type == 'circle'" dark :small="small" :color="color" :disabled="disabled" :title="title" @click="actionParent">
    <span v-if="type != 'circle' || type == 'icon'">{{title}} </span>
    <v-icon small>{{icon}}</v-icon>
  </v-btn>
</template>
<script>
export default {
  Name: 'dotms_comp_square_button',
  props: {
    small:{
      type: Boolean,
      default: true
    },
    btndefault:{
      type: Boolean,
      default: true
    },
    btnlink:{
      type: Boolean,
      default: false
    },
    Name: {
      type: String,
      default: 'dotms_object_feature_function'
    },
    type:{
      type: String,
      default: 'square'
    },
    title:{
      type: String,
      default: 'Button'
    },
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    action: {
      type: Function,
      default: () => this.actionParent()
    },
  },
  data () {
    return {
      shown: this.Name ? true : false
    }
  },
  methods: {
    actionParent() {
      this.$emit('action');
    }
  }
}
</script>