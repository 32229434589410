<template>
  <v-row :class="classForm">
    <v-col  :cols="titleCols" md="2">
      <v-subheader class="title-select pl-0 pr-0" v-text="'Filter By'"></v-subheader>
    </v-col>
    <v-col cols="10" md="3" class="col-select">
      <v-select v-model="selectedData" :items="items" item-value="value" item-text="text"  @change="$emit('getSelected', selectedData)" 
          menu-props="auto"  hide-details :label="classForm!=''?'Filter Select':''" :single-line="false" dense outlined></v-select>
    </v-col>
    <v-col :cols="newCols" md="4" class="col-search">
      <v-text-field class="search" placeholder="Placeholder" outlined hide-details dense outline
        label="Keyword" v-model="vmodel" @keyup="$emit('getValue', vmodel)" ref="fileTextField" append-icon="mdi-magnify" ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'dotms_comp_search',
    props: {
      v_model: {
        type: String,
        default: ''
      },
      classForm:{
        type: String,
        default: 'form-search'
      },
      newCols:{
        type: Number,
        default: 3
      },
      titleCols:{
        type: Number,
        default: 3
      },
      datas: {
        type: [Array, String],
        default: () => []
      },
      selectData: {
        type: [Array, String],
        default: () => []
      },
      label: {
        type: String,
        default: 'Component Select'
      },
      multiple: {
        type: Boolean,
        default: true
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      available: {
        type: Boolean,
        default: true
      },
      onClick: {
        type: Function
      },
      isActiveFor: {
        type: String
      }
    },
    data () {
      return {
        items: this.datas?[{text:'ALL', value: ''}].concat(this.datas.filter(x=>x.value!=''&&x.text!=''&&x.value!='actions')):[{text:'ALL', value: ''}],
        selectedData: this.selectData?this.selectData:'',
        vmodel: this.v_model?this.v_model:'',
      }
    },
    computed: {
      likesAllDatas () {
        return this.selectedDatas.length === this.items.length
      },
      likesSomeDatas () {
        return this.selectedDatas.length > 0 && !this.likesAllDatas
      },
      icon () {
        if (this.likesAllDatas) return 'mdi-close-box'
        if (this.likesSomeDatas) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      }
    },
    methods: {
      toggle () {
        this.$nextTick(() => {
          if (this.likesAllDatas) {
            this.selectedDatas = []
          } else {
            this.selectedDatas = this.datas.slice()
          }
        })
      }
    },
    watch:{
      selectData(val){
        this.selectedData = val
      },
      v_model(val){
        // console.log(val);
        this.vmodel = val
      }
    }
  }
</script>