<template>
  <div style="float:right">
    <v-btn v-if="btnclose === true" fab small style="float:right;margin-left:5px" color="white" title="Close Page" Name="dotms.master.scope.uploadpage.hbuttonround.closePage" @click="$emit('closePage')"><strong><v-icon>mdi-close</v-icon></strong></v-btn>
    <v-btn v-if="btnrefresh === true" fab small style="float:right;margin-left:5px" color="white" title="Refresh Page" Name="dotms.master.scope.uploadpage.hbuttonround.reloadpage" @click="$emit('reloadPage')"><v-icon>mdi-cached</v-icon></v-btn>
    <v-btn v-if="btnreset === true" fab small style="float:right;margin-left:5px" color="white" title="Reset Data" Name="dotms.master.scope.uploadpage.hbuttonround.resetdata" @click="$emit('resetData')"><v-icon> mdi-history</v-icon></v-btn>        
    <v-btn v-if="btnsetting === true" fab small style="float:right;margin-left:5px" color="white" title="Reset Data" Name="dotms.master.scope.uploadpage.hbuttonround.resetdata" @click="$emit('resetData')"><v-icon> mdi-wrench</v-icon></v-btn>        
    <v-icon v-if="btnchecklist === true" fab small style="float:left;margin-left:5px; margin-bottom:3px" color="green" title="Reset Data" Name="dotms.master.scope.uploadpage.hbuttonround.resetdata" @click="$emit('resetData')">  mdi-checkbox-marked-circle</v-icon>               
    <slot name="content" />
  </div>
</template>

<script>
export default {
  Name: 'dotms_comp_toolbar',
  props: {
    btnclose:{
      type: Boolean,
      default: false
    },
    btnrefresh:{
      type: Boolean,
      default: false
    },
    btnreset:{
      type: Boolean,
      default: false
    },
     btnsetting:{
      type: Boolean,
      default: false
    },
      btnchecklist:{
      type: Boolean,
      default: false
    },
    Name: {
      type: String,
      default: 'SquareButton'
    },
    title: {
      type: String,
      default: 'Title Object'
    },
    color: {
      type: String,
      default: 'secondary'
    },
    icon: {
      type: String,
      default: 'more_vert'
    },
    action: {
      type: Function,
      default: null
    },
  },
  data () {
    return {
      shown: this.Name ? true : false,
      close: this.btnclose ? this.btnclose : true,
      refresh: this.btnrefresh ? this.btnrefresh : true,
      reset: this.btnreset ? this.btnreset : true,
    }
  },
  methods: {
    actionParent () {
      this.$emit('action');
    },
  }
}
</script>

<style>
.btn-index-2 {
  z-index: 2 !important;
}
.font-white {
  color: #ffffff;
}
.btn-create {
  position: absolute;
  top: 35px;
  right: 20px;
}
.table-spacer {
  background-color: #ffffff;
  height: 20px;
}
.relative-card {
  position: relative;
}
.v-btn--fab.v-size--small{
  width: 25px !important;
  height: 25px !important;
}
</style>
