<template>
  <v-select v-model="selectmodel" :rules="fieldRules" :items="items" @input="$emit('input', selectmodel)" :item-value="itemValue" :item-text="itemText" :label="displayedLabel" :dense="dense" :multiple="multiple" :required="required" :disabled="disabled" :item-disabled="disabledItems" :return-object="returnObject" @click="onClick" outlined />
</template>

<script>
  export default {
    name: 'dotms_comp_select',
    props: {
      vModel: {
        type: [Number, String, Array, Object]
      },
      items: {
        type: Array,
        default: () => []
      },
      itemDisabled: {
        type: Function,
        default: () => false
      },
      label: {
        type: String,
        default: 'Component Select'
      },
      itemValue: {
        type: String,
      },
      itemText: {
        type: String,
      },
      multiple: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      enableMarkForRequiredField: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      returnObject: {
        type: Boolean,
        default: false
      },
      initValue: {
        type: Boolean,
        default: false
      },
      dense: {
        type: Boolean,
        default: false
      },
      rules: {
        type: Array,
        default: () => []
      },
      onClick: {
        type: Function,
        default: () => false
      }
    },
    data() {
      return {
        selectmodel: null,
        displayedLabel: "",
        fieldRules: [],
        disabledItems: []
      }
    },
    watch: {
      vModel(v) {
        this.selectmodel = v
      },
      itemDisabled(v) {
        this.disabledItems = v
      }
    },
    mounted() {
      this.selectmodel = this.vModel
      this.disabledItems = this.itemDisabled
      this.setInitValue()
      if(this.required && this.enableMarkForRequiredField)
        this.displayedLabel = this.label+"*"
      else 
        this.displayedLabel = this.label
      // if(this.required)
      //   this.fieldRules.push(v => !!v || this.label+' is required')
      this._.forEach(this.rules, (val, key) => {
          this.fieldRules.push(val)
      });
    },
    methods: {
      setInitValue() {
        if(!this.vModel && this.initValue && this.items.length > 0) {
          this.selectmodel = this.items[0]
          this.$emit('input', this.selectmodel)
        }
      }
    }
  }
</script>