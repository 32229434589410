export default {
    drawer: null,
    color: (type) => type == 's' ? 'success' : type == 'e' ? 'error' : type == 'w' ? 'warning' : type == 's' ? 'secondary' : type == 'i' ? 'info' : 'primary',
    image: 'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg',
    enum: {
        US_TP1: `${process.env.VUE_APP_BASE_URL}/template/T01_itdatemplate-appportaluploadDYNscope_v1_20201216.xlsx`,
        US_TP2: `${process.env.VUE_APP_BASE_URL}/template/T02_itdatemplate-appportaluploadDYNscope_v1_20201216.xlsx`,
        US_TP5: `${process.env.VUE_APP_BASE_URL}/template/T05v2_PSO-Manual_v1_20220614.xlsx`,
        Login_NIK_Title: `NIK`,
        Login_NIP_Title: `NIP`,
        Login_Other_Title: `Other`,
        Login_Admin_Title: `Administrator`,
        TH_USERS_MAIN: [
            { text: 'NIK', value: 'sKtpNo', sortable: true, align: 'left' },
            { text: 'Nama', value: 'sName', sortable: true, align: 'left' },
            { text: 'Contact', value: 'sContact', sortable: false, align: 'left' },
            { text: 'Email', value: 'sEmail', sortable: false, align: 'left' },
            { text: 'Role', value: 'sRole', sortable: true, align: 'center' },
            { text: 'Status', value: 'iStatus', sortable: false, align: 'center' },
            { text: 'Action', value: 'actions', sortable: false, align: 'center' },
        ],
        TH_VOTERS_MAIN: [
            { text: 'Kecamatan', value: 'kecName', sortable: true, align: 'left' },
            { text: 'Kelurahan', value: 'kelName', sortable: true, align: 'left' },
            { text: 'TPS', value: 'tpsName', sortable: true, align: 'center' },
            { text: 'Nama', value: 'sName', sortable: true, align: 'left' },
            { text: 'Gender', value: 'sGender', sortable: true, align: 'center' },
            { text: 'Age', value: 'iAge', sortable: true, align: 'center' },
            { text: 'Address', value: 'sAddress', sortable: false, align: 'left' },
            { text: 'RT', value: 'sRT', sortable: false, align: 'center' },
            { text: 'RW', value: 'sRW', sortable: false, align: 'center' },
            { text: 'Voters', value: 'iVoters', sortable: true, align: 'center' },
            { text: 'Action', value: 'actions', sortable: false, align: 'center' },
        ],
    }
}