<template>
<v-card class="mx-auto flex" shaped style="display:flex;background:#E5E5E5;margin:6px;border-radius:6px">
  <v-card class="mx-auto flex" shaped :style="`cursor:pointer;display:flex;background:${bgColor1};${styles1}`" @click="action1">
    <div>
      <v-card-title :style="`color:${textColor1}!important;font-size:2rem;${textStyles1}`">{{headcount1}} <div v-if="subheadcount1!=''" style="font-size:1rem!important">{{subheadcount1}}</div></v-card-title>
      <v-card-subtitle :style="`color:${textColor1}!important;font-size:1rem;`">{{title1}}</v-card-subtitle>
    </div>
    <div style="position:absolute; top:0; right:0; background:white">
      <v-avatar v-if="img1!=''"><v-img :src="`@/assets/img/${img1}`"/></v-avatar>
      <v-avatar x-large v-else><v-icon :color="bgColor1">{{icon1}}</v-icon></v-avatar>
    </div>
  </v-card>
  <div v-if="addInfo1.length>0" :style="`padding-right:20px`">
    <div v-for="(x,y) in addInfo1" :key="y" style="cursor:pointer;padding:16px 0 0 0;">
      <v-badge bordered :color="bgColor1" overlap :content="x.value" :value="x.value" @click="subaction1">
        <v-btn small class="white--text" :color="bgColor1" depressed>{{x.text}}</v-btn>
      </v-badge>
    </div>
  </div>
  <v-card class="mx-auto flex" shaped :style="`cursor:pointer;display:flex;background:${bgColor2};${styles2}`" @click="action2">
    <div>
      <v-card-title :style="`color:${textColor2}!important;font-size:2rem;${textStyles2}`">{{headcount2}} <div v-if="subheadcount2!=''" style="font-size:1rem!important">{{subheadcount2}}</div></v-card-title>
      <v-card-subtitle :style="`color:${textColor2}!important;font-size:1rem;`">{{title2}}</v-card-subtitle>
    </div>
    <div style="position:absolute; top:0; right:0; background:white">
      <v-avatar v-if="img2!=''"><v-img :src="`@/assets/img/${img2}`"/></v-avatar>
      <v-avatar x-large v-else><v-icon :color="bgColor2">{{icon2}}</v-icon></v-avatar>
    </div>
  </v-card>
  <div v-if="addInfo2.length>0" :style="`padding-right:20px`">
    <div v-for="(x,y) in addInfo2" :key="y" style="cursor:pointer;padding:16px 0 0 0;">
      <v-badge bordered :color="bgColor2" overlap :content="x.value" :value="x.value" @click="subaction2">
        <v-btn small class="white--text" :color="bgColor2" depressed>{{x.text}}</v-btn>
      </v-badge>
    </div>
  </div>
</v-card>
</template>

<script>
export default {
  name: 'dotms_comp_widget2',
  props: {
    addInfo1: {
      type: Array,
      default: () => []
    },
    styles1: {
      type: String,
      default: ''
    },
    bgColor1: {
      type: String,
      default: 'white'
    },
    textColor1: {
      type: String,
      default: ''
    },
    textStyles1: {
      type: String,
      default: ''
    },
    headcount1: {
      type: String,
      default: ''
    },
    subheadcount1: {
      type: String,
      default: ''
    },
    title1: {
      type: String,
      default: ''
    },
    img1: {
      type: String,
      default: ''
    },
    icon1: {
      type: String,
      default: ''
    },
    addInfo2: {
      type: Array,
      default: ()=> []
    },
    styles2: {
      type: String,
      default: ''
    },
    bgColor2: {
      type: String,
      default: 'white'
    },
    textColor2: {
      type: String,
      default: ''
    },
    textStyles2: {
      type: String,
      default: ''
    },
    headcount2: {
      type: String,
      default: ''
    },
    subheadcount2: {
      type: String,
      default: ''
    },
    title2: {
      type: String,
      default: ''
    },
    img2: {
      type: String,
      default: ''
    },
    icon2: {
      type: String,
      default: ''
    },
  },
  methods:{
    action1(){
      this.$emit('action1');
    },
    subaction1(){
      this.$emit('subaction1');
    },
    action2(){
      this.$emit('action2');
    },
    subaction2(){
      this.$emit('subaction2');
    }
  }
}
</script>
<style scoped>
.addInfo{
  position: absolute;
  right: 20px;
  top: 60px;
}
</style>