const message = {
    type: `form`,
    success: {
        0: (p) => p,
        1: () => `Valid`,
        2: () => `Sukses. Data telah terisi semua.`,
        3: () => `Sukses. Ready to execute`,
        4: () => `Sukses. Data Upload is Valid`,
           //tambahan
           161:(p)=> `Session masih aktif`,
    },
    error: {
        0: (p) => p,
        1: (p) => `Not Valid`,
        100: (p) => `Ditolak. Field${p?' '+p:''} harus diisi`,
        101: (p) => `Maximal Data Upload adalah${p?' '+p:''}`,
        102: (p) => `Ditolak. File belum ada`,
        103: (p) => `Ditolak. File harus dipilih!`,
        104: (p) => `Excel Template tidak dikenal. Anda hanya diperbolehkan menggunakan template ${p?' '+p:''}`,
        105: (p) => `${p} tidak boleh kosong`,
        106: (p) => `Data telah ada${p?' '+p:''}`,
        107: (p) => `${p} tidak terdaftar`,
        108: (p) => `User anda tidak memiliki hak otorisasi${p?' '+p:''}`,
        109: (p) => `Format data${p?' '+p:''} tidak sesuai`,
        110: (p, d) => `Panjang karakter${p?' '+p:''} tidak sesuai.${d?' '+d:''}`,
        111: (p) => `Data${p?' '+p:''} belum terdaftar, harap menyertakan data pada file`,
        112: (p) => `Data${p?' '+p:''} telah terdaftar di database, harap tidak menyertakan data pada file`,
        113: (p, n, m, d) => `Format ${p?' '+p:''} Min ${n} Digit dan Max Min ${m} Digit${d?'. '+d:''}`,
        114: (n, m, o) => `Format Name: Min ${n} Digit pertama huruf, digit ke ${m} harus ${o}`,
        115: (p) => `Format Name: ${p} Digit pertama harus sama dengan ${p} pertama pada parent`,
        116: (p, x, xl, y, yl) => `Format Name: harus ${p} Digit. ${x} Digit pertama adalah ${xl}, digi ke ${y} adalah ${yl}`,
        117: (p, d) => `${p} belum terdaftar${d?' '+d:''}`,
        118: (p, l, d) => `${p} dan ${l} tidak berada dalam ${d} yang sama`,
        119: (p, d) => `Data ${p} belum terdaftar. Harap melengkapi data ${d.join(',')}`,
        120: (p) => `Scope Type harus berisi ${p}`,
        121: (p) => `Data ${p} belum lengkap`,
        122: (p) => `Ditolak. Tidak ada data untuk diproses.`,
        123: (p) => `Data yang sama telah ada pada file.${p?p:''}`,
        124: (p) => `Deskripsi tidak mengandung kata "${p}".`,
        125: (p) => `Deskripsi hanya mengandung kata "${p}".`,
        126: (p) => `OTHER PARENT REFERENCE (OPR) untuk kategori ${p} tidak diperlukan.`,
        127: (p,y) => `OTHER PARENT REFERENCE (OPR) untuk kategori ${p} harus ${y}`,
        200: (p) => `Format ratio harus berupa [digit]:[digit]`,
           //tambahan
           161:(p)=> `Session tidak aktif`,
    },
    confirm:{
        0: () => `Masih ada In-process/Executed raw data, reset dan tulis-ulang dengan raw data baru?`,
        1: () => `Terdapat perbedaan raw data pada file, reset dan tulis-ulang dengan raw data baru?`
    }
}

export { message }