<template>
  <div class="attribute-value">{{ text === '' ? '-' : text }}</div>
</template>

<script>
export default {
  props: [
    'text'
  ]
}
</script>

<style>
.attribute-value {
  font-weight: 300;
}
</style>
