<template>
    <v-container class="header-maps">
      <v-flex xs12 md12 class="title-maps">
        <v-row>
          <v-col 
            cols="1"
            sm="1"
            >
            <v-icon slot="append" class="text-danger">mdi-map-marker-radius-outline</v-icon>
          </v-col>
          <v-col 
            cols="11"
            sm="6"
            >
            <div class="title-location">
              <h2>Lokasi Maps</h2>
              <p>Pin maps akan tampil berdasarkan filter progress</p>
            </div>
          </v-col>
          <v-col 
            cols="4"
            sm="1"
            >
            <v-subheader class="title-select pl-0 pr-0" v-text="'Tampilan :'"></v-subheader>
          </v-col>
          <v-col 
            cols="8"
            sm="4"
            class="mb-0"
            >
            <p class="title-condition"> 
              <v-icon slot="append" class="text-danger mr-2">mdi-road</v-icon> Kondisi Jalan
            </p>
          </v-col>
        </v-row>
      </v-flex>
      <slot name="content" />
    </v-container>
</template>
  
<script>
    export default {
      name: 'dotms_comp_mapcard',
      props: {
          
      },
      methods: {
          
      },
    }
</script>
<style scoped>

</style>