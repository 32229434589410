<template>
  <router-link class="link-transparent" :to="to">
    <v-icon color="yellow lighten-1" x-large>add_circle</v-icon>
  </router-link>
</template>

<script>
export default {
  props: [
    'to'
  ]
}
</script>

<style>
.link-transparent {
  color: transparent !important;
}
</style>
