<template>
  <v-card :color="color">
    <v-card-title class="py-0 relative-card">
      <v-flex xs12 md4>
        <h1 class="font-white title">{{ title }}</h1>
      </v-flex>
      <v-flex xs12 md4 v-if="withAttribute && (headerless !== 'yes')">
        <slot name="search"></slot>
      </v-flex>
      <v-flex xs12 md4>
        <v-btn
          v-if="withAttribute && (withButton !== 'no') && (typeof this.btncon!='undefined'? this.btncon : true)"
          class="btn-index-2" :color="typeof this.btncolor!='undefined'?btncolor:'secondary'"
          fab dark small @click="createRedirect" style="float:right">
          <v-icon>{{ iconbtn&&iconbtn=='edit'?'edit':'add' }}</v-icon>
        </v-btn>
        <v-btn fab dark small style="float:right" color="secondary"><v-icon>filter</v-icon></v-btn>
      </v-flex>
    </v-card-title>
    <!-- <v-spacer class="table-spacer"/>
    <v-toolbar v-if="!withAttribute && (headerless !== 'yes')" flat color="white">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="createRedirect" color="primary" v-if="(withButton !== 'no' && available) && (typeof this.btncon!='undefined'? this.btncon : true)">
        {{ iconbtn&&iconbtn=='edit'?'Edit':'Create' }}
      </v-btn>
    </v-toolbar> -->
    <slot name="table"/>
  </v-card>
</template>

<script>
export default {
  props: ['title', 'slug', 'actpopup', 'btncon', 'btncolor', 'withAttribute', 'headerless', 'withButton', 'iconbtn'],
  data () {
    return {
      color: this.withAttribute ? 'primary' : '',
      available: true
    }
  },
  methods: {
    createRedirect () {
      if(this.actpopup){
        this.$parent.opendialog(this.actpopup)
      }else{
        this.$router.push(this.slug + '/create')
      }
    }
  }
}
</script>

<style>
.btn-index-2 {
  z-index: 2 !important;
}
.font-white {
  color: #ffffff;
}
.btn-create {
  position: absolute;
  top: 35px;
  right: 20px;
}
.table-spacer {
  background-color: #ffffff;
  height: 20px;
}
.relative-card {
  position: relative;
}
</style>
