<template>
    <div class="text-caption">
        <div class="text-grey caption-title">{{ title }}</div>
        <div v-if="type==='text'" :class="`font-weight-bold caption-content ${classContent}`">{{ content }}</div>
        <v-chip v-else-if="type==='chip'" :class="`font-weight-bold caption-content ${classContent}`" variant="elevated"> {{ content }} </v-chip>
    </div>
</template>
<style>
.caption-title {
    font-size: 12px;
    color:#9E9E9E;
}

.caption-content {
    font-size: 14px;
}
</style>
<script>
export default {
    name: "dotms_comp_text",
    props: {
        styles: {
            type: String,
            default: "",
        },
        subtitleStyle: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "Title",
        },
        content: {
            type: String,
            default: "Content Text",
        },
        color: {
            type: String,
            default: "white",
        },
        fontType: {
            type: String,
            default: "Poppins, sans-serif",
        },
        fontSize: {
            type: Number,
            default: 14,
        },
        classContent: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'  
        }
    },
};
</script>