import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '@/store'
import paths from './paths'

function route(path, view, name, meta) {
    return {
        name: name || '',
        path,
        meta,
        component: (resolve) =>
            import (`@/pages/${view}.vue`).then(resolve)
    }
}
Vue.use(Router)

// Create a new router
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes: paths.map(path => route(path.path, path.view, path.name, path.meta)).concat([{ path: '*', redirect: '/login' }]),
    renderError(h, err) {
        console.warn(err)
    },
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition
        if (to.hash) return { selector: to.hash }
        return { x: 0, y: 0 }
    }
})
Vue.use(Meta)

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/isSessionActive']===true) {
            next()
            return
        }else{
            next('/login')
            return
        }
    }else{
        next()
    }
})

export default router