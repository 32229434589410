<template>
  <v-card class="py-3 px-3">
    <slot name="forms"></slot>
    <div class="mt-5 mb-5" v-if="!!this.$slots['table-form']"/>
    <slot name="table-form"></slot>
    <v-divider class="mt-5 mb-3" v-if="!!this.$slots['actions'] && (!!this.$slots['table-form'] || !!this.$slots['forms'])"/>
    <v-card-actions>
      <slot name="actions"></slot>
    </v-card-actions>
    <slot name="footer-form"></slot>
  </v-card>
</template>

<script>
export default {}
</script>
