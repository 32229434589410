<template>
  <div>
    <div class="breadcrumb">
      <v-icon v-if="isBack" @click="actionParent" :color="color" style="margin-right: 10px;">mdi-arrow-left-circle-outline</v-icon>
      <span class="parent-title">{{ parentTitle }}</span>
      <span class="subtitle" v-if="title" 
      :style="`${styles}color:${color}!important;font-family:${fontType}!important;`">{{ title }}</span>
    </div>
    <div class="breadcrumb-nav" v-if="showNav">
      <!-- <v-icon :color="color">mdi-arrow-left-circle-outline</v-icon> -->
      <!-- <a v-if="showBack" :href="href" style="text-decoration: none;"><v-icon class="mr-2" :color="color">mdi-arrow-left</v-icon></a> -->
      <span>{{title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "dotms_comp_breadcrumb",
  props: {
    isBack: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: String,
      default: "",
    },
    subtitleStyle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Header Title",
    },
    parentTitle: {
      type: String,
      default: "Home",
    },
    color: {
      type: String,
      default: "#C91C22",
    },
    fontType: {
      type: String,
      default: "Sen-Reguler,sans-serif",
    },
    showNav: {
      type: Boolean,
      default: false
    },
    actionBack: {
      type: Function,
      default: () => this.actionParent()
    },
  },
  methods: {
    actionParent() {
      this.$emit('actionBack');
    }
  }
};
</script>