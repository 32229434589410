import { render, staticRenderFns } from "./FullLogo.vue?vue&type=template&id=4516c699"
import script from "./FullLogo.vue?vue&type=script&lang=js"
export * from "./FullLogo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports