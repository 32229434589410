<template>
  <span class="clickable-span" @click="whenClicked">{{text}}</span>
</template>

<script>
export default {
  props: [
    'text'
  ],
  methods: {
    whenClicked () {
      this.$emit('whenClicked')
    }
  }
}
</script>

<style>
.clickable-span {
  cursor: pointer;
  color: #00bd8e;
}
</style>
